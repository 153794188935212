import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import SIDEBAR_NAVIGATION from "../../../shared/constants/sidebar-navigation.constant";
import {useLocation} from "react-router";


const Small = () => {
  const {userAccount} = useSelector(state => state.Auth)
  const location = useLocation()

  return (
    <nav id="navigation-widget-small"
         className="navigation-widget navigation-widget-desktop closed sidebar left delayed">

      <Link className="user-avatar small no-outline online"
            to={`${userAccount?.account?.role === 'channel' ? `/channel/${userAccount?.profile?.username}` : '/hub/profile/info'}`}>

        <div className="user-avatar-content">
          <div className="hexagon-image-30-32" data-src={userAccount?.profile?.photoURL}/>
        </div>

        <div className="user-avatar-progress">
          <div className="hexagon-progress-40-44"/>
        </div>

        <div className="user-avatar-progress-border">
          <div className="hexagon-border-40-44"/>
        </div>


        <div className="user-avatar-badge">
          <div className="user-avatar-badge-border">
            <div className="hexagon-22-24"/>
          </div>

          <div className="user-avatar-badge-content">
            <div className="hexagon-dark-16-18"/>
          </div>

          <p className="user-avatar-badge-text">1</p>
        </div>
      </Link>


      <ul className="menu small">
        {SIDEBAR_NAVIGATION.map(nav => nav.show ? (
          <li key={nav.id} className={`menu-item ${location.pathname === nav.link ? 'active' : ''}`}>
            <Link className="menu-item-link text-tooltip-tfr" to={nav.link} data-title={nav.label}>
              <svg className={`menu-item-link-icon ${nav.svgClass}`}>
                <use xlinkHref={nav.svgIcon}/>
              </svg>
            </Link>
          </li>
        ): '')}
      </ul>
    </nav>
  )
}

export default Small