import React from "react";
import useSidebar from "../../shared/hooks/sidebar.hook";
import FloatyBar from "../../components/widgets/actions/floaty-bar";
import Nav from "./nav";
const Sidebar = () => {
  useSidebar();

  return (
    <>
      <Nav />
      <FloatyBar />
    </>
  );
};

export default Sidebar;
