import React from "react";
import { Link } from "react-router-dom";
import avatar3 from "../../../../assets/img/avatar/03.jpg";

const NotificationBox = ({ notification }) => {
  return (
    <div className="notification-box">
      <div className="user-status notification">
        <Link className="user-status-avatar" to="/streamer/ohnono/about">
          <div className="user-avatar small no-outline">
            <div className="user-avatar-content">
              <div className="hexagon-image-30-32" data-src={avatar3} />
            </div>
            <div className="user-avatar-progress">
              <div className="hexagon-progress-40-44" />
            </div>
            <div className="user-avatar-progress-border">
              <div className="hexagon-border-40-44" />
            </div>
            <div className="user-avatar-badge">
              <div className="user-avatar-badge-border">
                <div className="hexagon-22-24" />
              </div>
              <div className="user-avatar-badge-content">
                <div className="hexagon-dark-16-18" />
              </div>
              <p className="user-avatar-badge-text">16</p>
            </div>
          </div>
        </Link>

        <p className="user-status-title">
          <Link className="bold" to="/streamer/Nick.Grissom">
            Nick Grissom
          </Link>{" "}
          posted a comment on your{" "}
          <Link className="highlighted" to="/streamer/Nick.Grissom">
            status update
          </Link>
        </p>
        <p className="user-status-timestamp small-space">2 minutes ago</p>
        <div className="user-status-icon">
          <svg className="icon-comment">
            <use xlinkHref="#svg-comment" />
          </svg>
        </div>
      </div>

      <div className="notification-box-close-button">
        <svg className="notification-box-close-button-icon icon-cross">
          <use xlinkHref="#svg-cross" />
        </svg>
      </div>
      <div className="mark-unread-button" />
    </div>
  );
};

export default NotificationBox;
