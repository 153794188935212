import {all, call, put, takeLatest} from 'redux-saga/effects'
import AccountSlice from "../reducers/account.reducer";
import AccountService from "../services/account.service";
import AuthSlice from "../reducers/auth.reducer";

const {
  getProfile,
  getProfileSuccess,
  getProfileFailed,
  saveProfile,
  saveProfileSuccess,
  saveProfileFailed,
  uploadMedia,
  uploadMediaSuccess,
  uploadMediaFailed,
  saveSocialMedia,
  saveSocialMediaSuccess,
  saveSocialMediaFailed,
  getUsername,
  getUsernameSuccess,
  getUsernameFailed,
  saveUsername,
  saveUsernameSuccess,
  saveUsernameFailed,
} = AccountSlice.actions

const {
  updateUser,
  updateUserMedia,
  updateUserSocial
} = AuthSlice.actions

function* getProfileSaga(action) {
  try {
    const data = yield call(AccountService.getProfile, action.payload)
    console.log({data})
    yield put(getProfileSuccess(data))
  } catch (e) {
    yield put(getProfileFailed(e))
  }
}

function* saveProfileSaga(action) {
  try {
    yield call(AccountService.saveProfile, action.payload)
    yield put(updateUser(action.payload))
    yield put(saveProfileSuccess())
  } catch (e) {
    yield put(saveProfileFailed(e))
  }
}

function* uploadMediaSaga(action) {
  try {
    const {profile} = yield call(AccountService.uploadMedia, action.payload)
    yield put(uploadMediaSuccess())
    yield put(updateUserMedia({
      uploadedMedia: profile
    }))
  } catch (e) {
    yield put(uploadMediaFailed(e))
  }
}

function* saveSocialMediaSaga(action) {
  try {
    yield call(AccountService.saveSocial, action.payload)
    yield put(saveSocialMediaSuccess())
    yield put(updateUserSocial(action.payload.social))
  } catch (e) {
    yield put(saveSocialMediaFailed(e))
  }
}

function* getUsernameSaga(action) {
  try {
    const data = yield call(AccountService.getUsername, action.payload)
    yield put(getUsernameSuccess(data))
  } catch (e) {
    yield put(getUsernameFailed(e))
  }
}

function* saveUsernameSaga(action) {
  try {
    const username = yield call(AccountService.saveUsername, action.payload)
    yield put(saveUsernameSuccess(username))
    yield put(updateUser({
      user: {
        username: username
      }
    }))
  } catch (e) {
    yield put(saveUsernameFailed(e))
  }
}

export default function* root() {
  yield all([
    takeLatest(getProfile, getProfileSaga),
    takeLatest(saveProfile, saveProfileSaga),
    takeLatest(uploadMedia, uploadMediaSaga),
    takeLatest(getUsername, getUsernameSaga),
    takeLatest(saveUsername, saveUsernameSaga),
    takeLatest(saveSocialMedia, saveSocialMediaSaga),
  ])
}