import {useEffect} from "react";
import {Link} from 'react-router-dom'
import appHelper from "../../../../shared/helpers/app.helper";
import useHexagon from "../../../../shared/hooks/hexagon.hook";
import avatar5 from '../../../../assets/img/avatar/05.jpg'

const CommentList = () => {
  useHexagon()
  useEffect(() => {
    appHelper.querySelector('#chat-box', function (el) {
      const popup = el[0],
        widgetBoxScrollable = popup.querySelector('.widget-box-scrollable'),
        postCommentForm = popup.querySelector('.post-comment-form');

      const updateWidgetBoxScrollableDimensions = function () {
        widgetBoxScrollable.style.height = `400px`;
      };

      updateWidgetBoxScrollableDimensions();
      window.addEventListener('resize', updateWidgetBoxScrollableDimensions);
    });

    appHelper.querySelector('.form-input', function (elements) {
      for (const el of elements) {
        if (el.classList.contains('always-active')) continue;

        const input = el.querySelector('input'),
          textarea = el.querySelector('textarea'),
          activeClass = 'active';

        let inputItem = undefined;

        if (input) inputItem = input;
        if (textarea) inputItem = textarea;

        if (inputItem) {
          inputItem.addEventListener('focus', function () {
            el.classList.add(activeClass);
          });

          inputItem.addEventListener('blur', function () {
            if (inputItem.value === '') {
              el.classList.remove(activeClass);
            }
          });
        }
      }
    });

  }, [])

  return (
    <div id="chat-box" className="widget-box no-padding">
      <h3 className="bordered-bottom" style={{padding: 20}}>
        Chat
      </h3>
      <div className="widget-box-scrollable" style={{overflow: 'auto'}} data-simplebar>
        <div className="post-comment-list">
          <div className="post-comment">
            <Link className="user-avatar small no-outline" to="about">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={avatar5}/>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"/>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"/>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"/>
                </div>
                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"/>
                </div>

                <p className="user-avatar-badge-text">12</p>
              </div>
            </Link>
            <p className="post-comment-text"><a className="post-comment-text-author" href="profile-timeline.html">Neko
              Bebop</a>It's always a pleasure to do this streams with you! If we have at least half the fun than last
              time
              it will be an incredible success!</p>
            <div className="content-actions">
              <div className="content-action">
                <div className="meta-line">
                  <p className="meta-line-timestamp">2 min ago</p>
                </div>
              </div>
            </div>
          </div>
          <div className="post-comment">
            <Link className="user-avatar small no-outline" to="about">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={avatar5}/>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"/>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"/>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"/>
                </div>
                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"/>
                </div>

                <p className="user-avatar-badge-text">12</p>
              </div>
            </Link>
            <p className="post-comment-text"><a className="post-comment-text-author" href="profile-timeline.html">Neko
              Bebop</a>It's always a pleasure to do this streams with you! If we have at least half the fun than last
              time
              it will be an incredible success!</p>
            <div className="content-actions">
              <div className="content-action">
                <div className="meta-line">
                  <p className="meta-line-timestamp">2 min ago</p>
                </div>
              </div>
            </div>
          </div>
          <div className="post-comment">
            <Link className="user-avatar small no-outline" to="about">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={avatar5}/>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"/>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"/>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"/>
                </div>
                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"/>
                </div>

                <p className="user-avatar-badge-text">12</p>
              </div>
            </Link>
            <p className="post-comment-text"><a className="post-comment-text-author" href="profile-timeline.html">Neko
              Bebop</a>It's always a pleasure to do this streams with you! If we have at least half the fun than last
              time
              it will be an incredible success!</p>
            <div className="content-actions">
              <div className="content-action">
                <div className="meta-line">
                  <p className="meta-line-timestamp">2 min ago</p>
                </div>
              </div>
            </div>
          </div>
          <div className="post-comment">
            <Link className="user-avatar small no-outline" to="about">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={avatar5}/>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"/>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"/>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"/>
                </div>
                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"/>
                </div>

                <p className="user-avatar-badge-text">12</p>
              </div>
            </Link>
            <p className="post-comment-text"><a className="post-comment-text-author" href="profile-timeline.html">Neko
              Bebop</a>It's always a pleasure to do this streams with you! If we have at least half the fun than last
              time
              it will be an incredible success!</p>
            <div className="content-actions">
              <div className="content-action">
                <div className="meta-line">
                  <p className="meta-line-timestamp">2 min ago</p>
                </div>
              </div>
            </div>
          </div>
          <div className="post-comment">
            <Link className="user-avatar small no-outline" to="about">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={avatar5}/>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"/>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"/>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"/>
                </div>
                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"/>
                </div>

                <p className="user-avatar-badge-text">12</p>
              </div>
            </Link>
            <p className="post-comment-text"><a className="post-comment-text-author" href="profile-timeline.html">Neko
              Bebop</a>It's always a pleasure to do this streams with you! If we have at least half the fun than last
              time
              it will be an incredible success!</p>
            <div className="content-actions">
              <div className="content-action">
                <div className="meta-line">
                  <p className="meta-line-timestamp">2 min ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="post-comment-form bordered-top">
        <div className="user-avatar small no-outline">
          <div className="user-avatar-content">
            <div className="hexagon-image-30-32" data-src={avatar5}/>
          </div>

          <div className="user-avatar-progress">
            <div className="hexagon-progress-40-44"/>
          </div>

          <div className="user-avatar-progress-border">
            <div className="hexagon-border-40-44"/>
          </div>

          <div className="user-avatar-badge">
            <div className="user-avatar-badge-border">
              <div className="hexagon-22-24"/>
            </div>
            <div className="user-avatar-badge-content">
              <div className="hexagon-dark-16-18"/>
            </div>
            <p className="user-avatar-badge-text">24</p>
          </div>
        </div>
        <form className="form">
          <div className="form-row">
            <div className="form-item">
              <div className="form-input small">
                <label htmlFor="post-reply">Your Message</label>
                <input type="text" id="post-reply" name="post_reply"/>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  )
}

export default CommentList