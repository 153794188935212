import {Route, Routes, Navigate} from "react-router-dom";
import React from "react";
import useHexagon from "../../shared/hooks/hexagon.hook";
import Profile from "./profile";
import Stream from "./stream";
import Timeline from "./timeline";
import About from "./about";
import Tournament from "./tournament";
import Announcements from "./announcements";

const ProfilePage = () => {
  
  return (
    <div>
      <Routes>
        <Route element={<Profile/>}>
          <Route path='*' element={<Navigate to="/news"/>}/>
          <Route path=':username/*' element={<Navigate to="news"/>}/>
          <Route path=':username/news' element={<Announcements/>}/>
          <Route path=':username/tournament' element={<Tournament/>}/>
        </Route>
      </Routes>
    </div>
  )
}
export default ProfilePage