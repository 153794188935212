const Messages = () => {
  return (
    <div className="action-list-item-wrap">
      <div className="action-list-item" title="Coming Soon">
        <svg className="action-list-item-icon icon-messages">
          <use xlinkHref="#svg-messages" />
        </svg>
      </div>
    </div>
  );
  return (
    <div className="action-list-item-wrap">
      <div
        className="action-list-item header-dropdown-trigger"
        title="Coming Soon"
      >
        <svg className="action-list-item-icon icon-messages">
          <use xlinkHref="#svg-messages" />
        </svg>
      </div>
      <div className="dropdown-box header-dropdown">
        <div className="dropdown-box-header">
          <p className="dropdown-box-header-title">Messages</p>
          <div className="dropdown-box-header-actions">
            <p className="dropdown-box-header-action">Mark all as Read</p>
            <p className="dropdown-box-header-action">Settings</p>
          </div>
        </div>
        <div className="dropdown-box-list medium" data-simplebar>
          <a
            className="dropdown-box-list-item"
            href="hub-profile-messages.html"
          >
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div
                      className="hexagon-image-30-32"
                      data-src="img/avatar/04.jpg"
                    />
                  </div>
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44" />
                  </div>
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44" />
                  </div>
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24" />
                    </div>
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18" />
                    </div>
                    <p className="user-avatar-badge-text">6</p>
                  </div>
                </div>
              </div>
              <p className="user-status-title">
                <span className="bold">Bearded Wonder</span>
              </p>
              <p className="user-status-text">
                Great! Then will meet with them at the party...
              </p>
              <p className="user-status-timestamp floaty">29 mins ago</p>
            </div>
          </a>
          <a
            className="dropdown-box-list-item"
            href="hub-profile-messages.html"
          >
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div
                      className="hexagon-image-30-32"
                      data-src="img/avatar/05.jpg"
                    />
                  </div>
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44" />
                  </div>
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44" />
                  </div>
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24" />
                    </div>
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18" />
                    </div>
                    <p className="user-avatar-badge-text">12</p>
                  </div>
                </div>
              </div>
              <p className="user-status-title">
                <span className="bold">Neko Bebop</span>
              </p>
              <p className="user-status-text">Awesome! I'll see you there!</p>
              <p className="user-status-timestamp floaty">54 mins ago</p>
            </div>
          </a>
          <a
            className="dropdown-box-list-item"
            href="hub-profile-messages.html"
          >
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div
                      className="hexagon-image-30-32"
                      data-src="img/avatar/03.jpg"
                    />
                  </div>
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44" />
                  </div>
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44" />
                  </div>
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24" />
                    </div>
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18" />
                    </div>
                    <p className="user-avatar-badge-text">16</p>
                  </div>
                </div>
              </div>
              <p className="user-status-title">
                <span className="bold">Nick Grissom</span>
              </p>
              <p className="user-status-text">Can you stream that new game?</p>
              <p className="user-status-timestamp floaty">2 hours ago</p>
            </div>
          </a>
          <a
            className="dropdown-box-list-item"
            href="hub-profile-messages.html"
          >
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div
                      className="hexagon-image-30-32"
                      data-src="img/avatar/07.jpg"
                    />
                  </div>
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44" />
                  </div>
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44" />
                  </div>
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24" />
                    </div>
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18" />
                    </div>
                    <p className="user-avatar-badge-text">26</p>
                  </div>
                </div>
              </div>
              <p className="user-status-title">
                <span className="bold">Sarah Diamond</span>
              </p>
              <p className="user-status-text">
                I'm sending you the latest news of the release...
              </p>
              <p className="user-status-timestamp floaty">16 hours ago</p>
            </div>
          </a>
          <a
            className="dropdown-box-list-item"
            href="hub-profile-messages.html"
          >
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div
                      className="hexagon-image-30-32"
                      data-src="img/avatar/12.jpg"
                    />
                  </div>
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44" />
                  </div>
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44" />
                  </div>
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24" />
                    </div>
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18" />
                    </div>
                    <p className="user-avatar-badge-text">10</p>
                  </div>
                </div>
              </div>
              <p className="user-status-title">
                <span className="bold">James Murdock</span>
              </p>
              <p className="user-status-text">
                Great! Then will meet with them at the party...
              </p>
              <p className="user-status-timestamp floaty">7 days ago</p>
            </div>
          </a>
          <a
            className="dropdown-box-list-item"
            href="hub-profile-messages.html"
          >
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div
                      className="hexagon-image-30-32"
                      data-src="img/avatar/10.jpg"
                    />
                  </div>
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44" />
                  </div>
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44" />
                  </div>
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24" />
                    </div>
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18" />
                    </div>
                    <p className="user-avatar-badge-text">5</p>
                  </div>
                </div>
              </div>
              <p className="user-status-title">
                <span className="bold">The Green Goo</span>
              </p>
              <p className="user-status-text">Can you stream that new game?</p>
              <p className="user-status-timestamp floaty">10 days ago</p>
            </div>
          </a>
        </div>
        <a
          className="dropdown-box-button primary"
          href="hub-profile-messages.html"
        >
          View all Messages
        </a>
      </div>
    </div>
  );
};

export default Messages;
