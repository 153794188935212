import {createSlice} from "@reduxjs/toolkit";
import {
  failedActionStatus,
  initActionStatus, resetActionStatus,
  successActionStatus
} from "../constants/status.constant";

const initialState = {
  status: {
    ...initActionStatus
  }
}

const AccountSlice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    getProfile: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'getProfile'
        }
      }
    },
    getProfileSuccess: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...successActionStatus,
          type: 'getProfile'
        }
      }
    },
    getProfileFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'getProfile'
        }
      }
    },
    getUsername: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'getUsername'
        }
      }
    },
    getUsernameSuccess: (state, {payload}) => {
      return {
        ...state,
        usernameValidate: {
          isValid: !payload?.existing,
          ownerId: payload?.userId
        },
        status: {
          ...successActionStatus,
          type: 'getUsername'
        }
      }
    },
    getUsernameFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'getUsername'
        }
      }
    },
    saveUsername: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'saveUsername'
        }
      }
    },
    saveUsernameSuccess: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...successActionStatus,
          type: 'saveUsername'
        }
      }
    },
    saveUsernameFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          message: payload?.message,
          type: 'saveUsername'
        }
      }
    },
    saveProfile: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'saveProfile'
        }
      }
    },
    saveProfileSuccess: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...successActionStatus,
          type: 'saveProfile'
        }
      }
    },
    saveProfileFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'saveProfile'
        }
      }
    },
    uploadMedia: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'uploadMedia'
        }
      }
    },
    uploadMediaSuccess: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...successActionStatus,
          type: 'uploadMedia'
        }
      }
    },
    uploadMediaFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'uploadMedia'
        }
      }
    },
    saveSocialMedia: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'saveSocialMedia'
        }
      }
    },
    saveSocialMediaSuccess: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...successActionStatus,
          type: 'saveSocialMedia'
        }
      }
    },
    saveSocialMediaFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'saveSocialMedia'
        }
      }
    },
    resetStatus: ({state}) => ({
      ...state, status: {
        ...resetActionStatus
      }
    })
  }
})

export default AccountSlice