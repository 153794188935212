import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import AuthSlice from "../reducers/auth.reducer"
import {useNavigate} from "react-router";
import WalletSlice from "../reducers/wallet.reducer";
import {v4 as uuidv4} from "uuid";

const useLogin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {status, currentUser, userAccount} = useSelector(state => state.Auth)
  const {userWallet, status: walletStatus} = useSelector(state => state.Wallet)
  const {registerUser, getUserAccount, reset} = AuthSlice.actions
  const {getWallet, createWallet} = WalletSlice.actions
  
  const registerUserAfterSignIn = () => {
    if (currentUser && userAccount) return
    
    const userData = {
      uid: currentUser?.uid,
      account: {
        uid: currentUser?.uid,
        email: currentUser?.email,
        role: 'fan',
      },
      profile: {
        name: currentUser?.displayName,
        phoneNumber: currentUser?.phoneNumber,
        photoURL: currentUser?.photoURL,
      }
    }
    dispatch(registerUser({user: userData}))
  }
  
  useEffect(() => {
    processUser()
  }, [])
  
  useEffect(() => {
    processUser()
  }, [status])
  
  useEffect(() => {
    processUser()
  }, [walletStatus])
  const proceedAsUser = () => {
    dispatch(reset())
    navigate('/welcome')
  }
  const processUser = () => {
    const signInSuccess = status?.submitted &&
      !status?.loading &&
      status?.success &&
      status?.type === 'authInit'
    
    const createUserSuccess = status?.submitted &&
      !status?.loading &&
      status?.success &&
      status?.type === 'createUser'
    
    const getUserAccountCalled = status?.submitted &&
      !status?.loading &&
      status?.type === 'getUserAccount'
    
    const registerUserSuccess = status?.submitted &&
      !status?.loading &&
      status?.success &&
      status?.type === 'registerUser'
    
    if (signInSuccess) {
      /*
      * Get the userAccount if we detect login is successful
      * */
      if (currentUser?.uid) dispatch(getUserAccount({uid: currentUser?.uid}))
      // dispatch(getWallet({uid: currentUser?.uid}))
    }
    
    if (getUserAccountCalled) {
      if (status.success) {
        proceedAsUser()
      } else {
        registerUserAfterSignIn()
      }
    }
    
    if (registerUserSuccess) {
      proceedAsUser()
    }
    
    // if (getUserAccountCalled && status?.success && walletStatus?.type !== 'getWallet') {
    //   dispatch(getWallet({uid: currentUser?.uid}))
    // }
    
    // if (getWalletCalled) {
    //   if (!userWallet || !Object.keys(userWallet)?.length) {
    //     registerUserAfterSignIn()
    //   } else {
    //     // dispatch(reset())
    //     // navigate('/streams')
    //   }
    // }
    
  }
}

export default useLogin