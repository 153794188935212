import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import useRegistration from "../shared/hooks/registration.hook";
import {ROUTES} from "../shared/constants/routes.constant";
import AuthSlice from "../shared/reducers/auth.reducer";
import {PrivateRoutes} from './private-routes'
import {ErrorsPage} from '../modules/errors'
import {AuthPage} from "../modules/auth";
import App from '../layout/app'
import useAuth from "../shared/hooks/auth.hook";

const AppRoutes = () => {
  const dispatch = useDispatch()
  const {authInit} = AuthSlice.actions

  useEffect(() => {
    dispatch(authInit())
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App/>}>
          <Route path='auth/*' element={<AuthPage/>}/>
          <Route path='*' element={<PrivateRoutes/>}/>
          <Route path='error/*' element={<ErrorsPage/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
