import React, {lazy, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import MasterLayout from '../layout/master-layout'
import {SuspensedView} from "../components/suspensed-view";
import ProfilePage from "../modules/profile/profile-page";
import {ROUTES} from "../shared/constants/routes.constant";
import {AboutPage} from "../modules/about";
import {ContactPage} from "../modules/contact-us";
import {ErrorsPage} from '../modules/errors'
import {ComingSoon} from "../modules/coming-soon";
import Welcome from "../modules/home/welcome";

const HubPage = lazy(() => import('../modules/hub'))
const HomePage = lazy(() => import('../modules/home'))
const StreamsPage = lazy(() => import('../modules/streams'))
const WalletPage = lazy(() => import('../modules/wallet'))

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route path={ROUTES.DASHBOARD?.WELCOME} element={<Welcome/>}/>
      <Route element={<MasterLayout/>}>
        <Route path={ROUTES.DASHBOARD?.INDEX} element={<HomePage/>}/>
        <Route path={ROUTES.ABOUT?.INDEX} element={<AboutPage/>}/>
        <Route path={ROUTES.CONTACT?.INDEX} element={<ContactPage/>}/>
        <Route path={`${ROUTES.STREAMS?.INDEX}/*`} element={
          <SuspensedView>
            <StreamsPage/>
          </SuspensedView>
        }/>
        <Route path={`${ROUTES.PROFILE?.WILDCARD}`} element={
          <SuspensedView>
            <ProfilePage/>
          </SuspensedView>
        }/>
        <Route path={`${ROUTES.WALLET?.INDEX}/*`} element={
          <SuspensedView>
            <WalletPage/>
          </SuspensedView>
        }/>
        <Route path={`${ROUTES.HUB?.INDEX}/*`} element={
          <SuspensedView>
            <HubPage/>
          </SuspensedView>
        }/>
        <Route path='error/*' element={<ErrorsPage/>}/>
        <Route path='soon/*' element={<ComingSoon/>}/>
        <Route path='*' element={<Navigate to={ROUTES.STREAMS?.INDEX}/>}/>
      </Route>
    </Routes>
  )
}


export {PrivateRoutes}
