export const ROUTES =
  {
    AUTH: {
      INDEX: '/auth',
    },
    DASHBOARD: {
      WELCOME: '/welcome',
      INDEX: '/dashboard'
    },
    ABOUT: {
      INDEX: '/about'
    },
    CONTACT: {
      INDEX: '/contact-us'
    },
    STREAMS: {
      INDEX: 'streams'
    },
    PROFILE: {
      WILDCARD: 'channel/*',
      INDEX: '/channel/:username',
      ABOUT: '/channel/:username/about',
      STREAM: '/channel/:username/livestream',
      TOURNAMENT: '/channel/:username/tournament',
    },
    WALLET: {
      INDEX: '/wallet'
    },
    HUB: {
      INDEX: '/hub',
      ACCOUNT_INFO: '/hub/account/info',
      PROFILE_INFO: '/hub/profile/info',
      NOTIFICATIONS: '/hub/account/notifications'
    }
  }
