import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import AuthSlice from "../../shared/reducers/auth.reducer";
import logo from "../../assets/images/eatsleepplay-lg.png";
import useLogin from "../../shared/hooks/login.hook";
import {Spinner} from "../../components/spinner";

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {register, handleSubmit, watch, formState: {errors}} = useForm()
  const {currentUser, status} = useSelector(state => state.Auth)
  const {
    signInWithEmailAndPassword,
    getUserAccount,
    signInWithRedirect
  } = AuthSlice.actions

  useLogin()

  const handleSignIn = (data) => {
    dispatch(signInWithEmailAndPassword(data))
  }

  const handleFacebookSignIn = () => {
    dispatch(signInWithRedirect({provider: 'facebook'}))
  }

  const handleGoogleSignIn = () => {
    dispatch(signInWithRedirect({provider: 'google'}))
  }

  useEffect(() => {
    if (status?.submitted && !status.loading) {
      if (status?.type === 'signInWithEmailAndPassword' && status.success) {
        
        dispatch(getUserAccount({uid: currentUser?.uid}))
      }
      // if (status?.type === 'authInit' && status.success) {
      //   navigate(`/streams`)
      // }

      if (status?.type === 'authInit' && !status?.success && status?.message) {
        console.log(status.message)
      }

    }
  }, [status])

  return (
    <div className="form-box login-register-form-element">
      <img src={logo} className="form-box-decoration " alt="eat sleep play"/>

      <h2 className="form-box-title">Account Login</h2>
      {status.loading && status?.type === 'authInit' ? (
        <div style={{padding: 10}}>
          <Spinner/>
        </div>
      ) : (
        <>
          {/*<form className="form" onSubmit={handleSubmit(handleSignIn)}>
            <div className="form-row">
              <div className="form-item">
                <div className="form-input active">
                  <label htmlFor="login-username">Email</label>
                  <input type="text" id="login-email" name="email" {...register("email", {required: true})}/>
                  {errors.email && <span>This field is required</span>}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-item">
                <div className="form-input active">
                  <label htmlFor="login-password">Password</label>
                  <input type="password" id="login-password"
                         name="password" {...register("password", {required: true})}/>
                  {errors.password && <span>This field is required</span>}
                </div>
              </div>
            </div>

            <div className="form-row space-between">
              <div className="form-item">
                <a className="form-link" href="#">Forgot Password?</a>
              </div>
            </div>

            <div className="form-row">
              <div className="form-item">
                <button id="login-submit" className="button medium secondary">Login to your Account!</button>
              </div>

            </div>

          </form>*/}

          <p className="lined-text">Login with your Social Account</p>

          <div className="social-links">
            <a className="social-link facebook" href="#" onClick={handleFacebookSignIn}>
              <svg className="icon-facebook">
                <use xlinkHref="#svg-facebook"/>
              </svg>
            </a>

            {/*<a className="social-link twitter" href="#">
          <svg className="icon-twitter">
            <use xlinkHref="#svg-twitter"/>
          </svg>
        </a>

        <a className="social-link twitch" href="#">
          <svg className="icon-twitch">
            <use xlinkHref="#svg-twitch"/>
          </svg>
        </a>*/}

            <a className="social-link youtube" href="#" onClick={handleGoogleSignIn}>
              <svg className="icon-google">
                <use xlinkHref="#svg-google"/>
              </svg>
            </a>
          </div>
        </>
      )}

    </div>
  )
}

export default Login