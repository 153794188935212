import Schedule from "./components/schedule";
import StreamVideo from "./components/stream-video";
import {Predictions} from "./components/predictions";
import {Routes, Route} from "react-router-dom";

const Stream = () => {
  return (
    <div className="grid grid-12">
      {/*<div className="grid-column">*/}
      {/*<div className="streamer-box">

          <figure className="streamer-box-cover liquid">
            <img src="img/cover/53.png" alt="cover-53"/>
          </figure>


          <div className="streamer-box-info">

            <div className="streamer-box-image">
              <figure className="picture medium circle liquid">
                <img src="img/avatar/01-social.png" alt="avatar-01-social"/>
              </figure>
            </div>

            <p className="streamer-box-title">GameHuntress</p>


            <p className="streamer-box-status">Offline</p>


            <div className="user-stats">

              <div className="user-stat">

                <p className="user-stat-title">149.4k</p>


                <p className="user-stat-text">followers</p>

              </div>


              <div className="user-stat">

                <p className="user-stat-title">136.2k</p>


                <p className="user-stat-text">following</p>

              </div>

            </div>


            <a className="button small twitch" href="https://www.twitch.tv/" target="_blank">Subscribe to my
              Channel!</a>

          </div>

        </div>*/}
      {/*<Faqs/>*/}
      {/*</div>*/}

      <div className="grid-column">
        {/*<Schedule/>*/}
        {/*<StreamVideo/>*/}
        <Predictions/>

      </div>

    </div>
  )
}

export default Stream