import React, {Suspense} from 'react'
import {Outlet} from "react-router"
import PageLoader from "../components/page-loader/page-loader";
import useAuth from "../shared/hooks/auth.hook";
import Content from "./core/content";

function App() {
  // useAuth()
  return (
    <Suspense fallback={<PageLoader/>}>
      <Outlet/>
    </Suspense>
  );
}

export default App;
