import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import cover1 from '../../../assets/img/cover/01.jpg'
import avatar1 from '../../../assets/img/avatar/01.jpg'
import appHelper from "../../../shared/helpers/app.helper";
import {Link} from "react-router-dom";
import useHexagon from "../../../shared/hooks/hexagon.hook";
import useOwner from "../../../shared/hooks/owner.hook";
import TinySlider from "tiny-slider-react";

const Header = ({channel}) => {
  const [coverLoaded, setCoverLoaded] = useState()
  useEffect(() => {
    setTimeout(() => {
      appHelper.querySelector('.liquid', function (images) {
        for (const image of images) {
          appHelper.liquidify(image);
        }
      })
    })
  }, [coverLoaded]);

  const photoRef = useRef()

  useEffect(() => {
    appHelper.plugins.createHexagon({
      container: '.hexagon-image-100-110',
      width: 100,
      height: 110,
      roundedCorners: true,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-148-164',
      width: 148,
      height: 164,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-progress-124-136',
      width: 124,
      height: 136,
      lineWidth: 8,
      roundedCorners: true,
      gradient: {
        colors: ['#d9ff65', '#40d04f']
      },
      scale: {
        start: 0,
        end: 1,
        stop: .1
      }
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-border-124-136',
      width: 124,
      height: 136,
      lineWidth: 8,
      roundedCorners: true,
      lineColor: '#293249'
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-40-44',
      width: 40,
      height: 44,
      roundedCorners: true,
      roundedCornerRadius: 1,
      fill: true,
      lineColor: '#1d2333'
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-dark-32-34',
      width: 32,
      height: 34,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#7750f8',
      fill: true
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-120-132',
      width: 120,
      height: 132,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-image-82-90',
      width: 82,
      height: 90,
      roundedCorners: true,
      roundedCornerRadius: 3,
      clip: true
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-border-100-110',
      width: 100,
      height: 110,
      lineWidth: 6,
      roundedCorners: true,
      lineColor: '#293249'
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-32-36',
      width: 32,
      height: 36,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#1d2333',
      fill: true
    });
    appHelper.plugins.createHexagon({
      container: '.profile-header .hexagon-dark-26-28',
      width: 26,
      height: 28,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#7750f8',
      fill: true
    });
  }, [channel, photoRef])

  const {username} = useParams()

  const {isOwner} = useOwner({username})

  return channel ? (
    <div className="profile-header">
      <figure className="profile-header-cover liquid">
        <img
          src={channel?.coverURL ?? cover1}
          alt="channel cover image"
          onLoad={() => setCoverLoaded(true)}
          onError={(e) => setCoverLoaded(false)}
        />
      </figure>

      <div className="profile-header-info">
        <div className="user-short-description big">
          <div className="user-short-description-avatar user-avatar big">
            <div className="user-avatar-border">
              <div className="hexagon-148-164"/>
            </div>
            <div className="user-avatar-content">
              <div ref={photoRef} className="hexagon-image-100-110" data-src={channel?.photoURL}/>
            </div>
            <div className="user-avatar-progress">
              <div className="hexagon-progress-124-136"/>
            </div>

            <div className="user-avatar-progress-border">
              <div className="hexagon-border-124-136"/>
            </div>

            <div className="user-avatar-badge">
              <div className="user-avatar-badge-border">
                <div className="hexagon-40-44"/>
              </div>

              <div className="user-avatar-badge-content">
                <div className="hexagon-dark-32-34"/>
              </div>

              <p className="user-avatar-badge-text">1</p>
            </div>

          </div>


          <div className="user-short-description-avatar user-short-description-avatar-mobile user-avatar medium"
          >
            <div className="user-avatar-border">
              <div className="hexagon-120-132"/>
            </div>

            <div className="user-avatar-content">
              <div className="hexagon-image-82-90" data-src={channel?.coverURL}/>
            </div>

            <div className="user-avatar-progress">
              <div className="hexagon-progress-100-110"/>
            </div>

            <div className="user-avatar-progress-border">
              <div className="hexagon-border-100-110"/>
            </div>

            <div className="user-avatar-badge">
              <div className="user-avatar-badge-border">
                <div className="hexagon-32-36"/>
              </div>
              <div className="user-avatar-badge-content">
                <div className="hexagon-dark-26-28"/>
              </div>
              <p className="user-avatar-badge-text">1</p>
            </div>

          </div>

          <p className="user-short-description-title">
            @{channel?.username}
          </p>
          <p className="user-short-description-text">
            {channel?.name}
          </p>

        </div>
        <div className="profile-header-social-links-wrap">
          {!channel?.social || true ? '' : (
            <TinySlider settings={{
              controlsContainer: '#profile-header-social-links-slider-controls',
              items: 6,
              gutter: 8,
              loop: false,
              nav: false,
              responsive: {
                481: {
                  gutter: 12
                }
              },
            }}>
              <div
                id="profile-header-social-links-slider"
                className="profile-header-social-links">

                {channel?.social?.facebook ? (
                  <div className="profile-header-social-link">
                    <a className="social-link facebook" href={`https://www.facebook.com/${channel?.social?.facebook}`}
                       target="_blank">
                      <svg className="icon-facebook">
                        <use xlinkHref="#svg-facebook"/>
                      </svg>
                    </a>
                  </div>
                ) : ''}

                {channel?.social?.twitter ? (
                  <div className="profile-header-social-link">
                    <a className="social-link twitter" href={`https://twitter.com/${channel?.social?.twitter}`}
                       target="_blank">
                      <svg className="icon-twitter">
                        <use xlinkHref="#svg-twitter"/>
                      </svg>
                    </a>
                  </div>
                ) : ''}

                {channel?.social?.instagram ? (
                  <div className="profile-header-social-link">
                    <a className="social-link instagram"
                       href={`https://www.instagram.com/${channel?.social?.instagram}`}
                       target="_blank">
                      <svg className="icon-instagram">
                        <use xlinkHref="#svg-instagram"/>
                      </svg>
                    </a>
                  </div>
                ) : ''}

                {channel?.social?.twitch ? (
                  <div className="profile-header-social-link">
                    <a className="social-link twitch" href={`https://www.twitch.tv/${channel?.social?.twitch}`}
                       target="_blank">
                      <svg className="icon-twitch">
                        <use xlinkHref="#svg-twitch"/>
                      </svg>
                    </a>
                  </div>
                ) : ''}

                {channel?.social?.youtube ? (
                  <div className="profile-header-social-link">
                    <a className="social-link youtube" href={`https://www.youtube.com/${channel?.social?.youtube}`}
                       target="_blank">
                      <svg className="icon-youtube">
                        <use xlinkHref="#svg-youtube"/>
                      </svg>
                    </a>
                  </div>
                ) : ''}

                {channel?.social?.discord ? (
                  <div className="profile-header-social-link">
                    <a className="social-link discord" href={`https://discord.com/invite/${channel?.social?.discord}`}
                       target="_blank">
                      <svg className="icon-discord">
                        <use xlinkHref="#svg-discord"/>
                      </svg>
                    </a>
                  </div>
                ) : ''}
              </div>
              <div id="profile-header-social-links-slider-controls" className="slider-controls">
                <div className="slider-control left">
                  <svg className="slider-control-icon icon-small-arrow">
                    <use xlinkHref="#svg-small-arrow"/>
                  </svg>
                </div>

                <div className="slider-control right">
                  <svg className="slider-control-icon icon-small-arrow">
                    <use xlinkHref="#svg-small-arrow"/>
                  </svg>
                </div>
              </div>
            </TinySlider>
          )}
        </div>

        {/*<div className="user-stats">
          <div className="user-stat big">
            <p className="user-stat-title">0</p>
            <p className="user-stat-text">posts</p>
          </div>
    
          <div className="user-stat big">
            <p className="user-stat-title">0</p>
            <p className="user-stat-text">friends</p>
          </div>
    
          <div className="user-stat big">
            <p className="user-stat-title">0</p>
            <p className="user-stat-text">visits</p>
          </div>
        </div>*/}

        <div className="profile-header-info-actions">
          {!isOwner ? (
            <p className="profile-header-info-action button secondary">Subscribe</p>
          ) : ''}
          {/*<p className="profile-header-info-action button primary">
          <span className="hide-text-mobile">Send</span> Message</p>*/}
        </div>

      </div>

    </div>
  ) : ''
}

export default Header