import React, {Suspense} from "react";
import {Outlet} from "react-router";
import PageLoader from "../../components/page-loader/page-loader";

const Layout = () => {
  return (
    <Suspense fallback={<PageLoader/>}>
      <Outlet/>
    </Suspense>
  )
}
export default Layout
