import {v4 as uuidv4} from "uuid";
import firebase from './firebase.service'
import {
  getDownloadURL,
  getStorage,
  uploadString,
  ref as storageRef
} from "firebase/storage";

import {
  remove as deleteData,
  update
} from "firebase/database";
import moment from "moment-timezone";

const {
  setData,
  get,
  ref,
  database,
  auth
} = firebase

const ProfileService = {
  savePost: async ({username, userId, photoURL, message}) => {
    const postId = uuidv4()
    const postData = {
      id: postId,
      author: {
        userId,
        username,
        photoURL,
      },
      message,
      date_created: moment().tz("America/Los_Angeles").format(),
      comments: [],
      reactions: [],
      attachments: [],
      shares: []
    }
    await setData({table: `posts/${userId}/${postId}`, data: postData})
    return {username, post: postData}
  },
  getPosts: async ({username}) => {
    const usernameRef = await get(ref(database, `usernames/${username}`))
    const userId = usernameRef.val()?.userId
    if (!userId) return {username, posts: {}}
    const postsRef = await get(ref(database, `posts/${userId}`))
    return {username, posts: postsRef.val()}
  }
}

export default ProfileService