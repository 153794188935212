import firebase from './firebase.service'
import {
  getDownloadURL,
  getStorage,
  uploadString,
  ref as storageRef
} from "firebase/storage";

import {
  remove as deleteData,
  update
} from "firebase/database";

const {
  setData,
  get,
  ref,
  database
} = firebase

const ChannelService = {
  getChannel: async ({username}) => {
    const usernameRef = await get(ref(database, `channels/${username}`))
    if (!usernameRef.val() || !usernameRef?.val()?.userId) throw {message: "Username does not exists"}
    const channelData = await get(ref(database, `accounts/${usernameRef?.val()?.userId}/profile`))
    if (!channelData.val()) throw {message: "Channel does not exists"}
    const socialData = await get(ref(database, `accounts/${usernameRef?.val()?.userId}/social`))

    return {...channelData.val(), social: socialData.val()}
  },
  getChannels: async ({isFeatured = null}) => {
    if (isFeatured) {
      const featuredRef = await get(ref(database, `featured/channels`))
      if (!featuredRef.val()) throw {message: "No featured channels today."}
      
      const channelList = await Object.keys(featuredRef.val())
        .map(async channel => {
            const channelInfo = await get(ref(database, `channels/${channel}`))
            
            if (!channelInfo.val()) return
            
            return {
              [channelInfo?.val()?.channelId]: channelInfo.val()
            }
          }
        )
      
      return Promise.all(channelList)
    } else {
      const channelsRef = await get(ref(database, `channels`))
      return channelsRef?.val()
    }
  },
  createChannel: async ({userAccount}) => {
    /*
    * Update role in accounts/:uid/account with 'channel' as flag
    * */
    const data = {
      role: 'channel'
    }
    const accountsRef = ref(database, `accounts/${userAccount?.uid}/account`)
    await update(accountsRef, data)
    
    /*
    * Create Channels Data with User Profile's Info as Default.
    * */
    const channelData = {
      userId: userAccount?.uid,
      photoURL: userAccount?.profile?.photoURL || '',
      coverURL: userAccount?.profile?.coverURL || '',
      channelId: userAccount?.profile?.username,
      channelName: userAccount?.profile?.name,
    }
    
    return await setData({table: `channels/${userAccount?.profile?.username}`, data: channelData})
  },
  
  updateChannel: async () => {
  }
}

export default ChannelService