import {createSlice} from "@reduxjs/toolkit";
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";

const initialState = {
  userWallet: {},
  status: {
    ...initActionStatus
  }
}

const WalletSlice = createSlice({
  name: 'Wallet',
  initialState,
  reducers: {
    createWallet: (state, {payload}) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'createWallet'
      }
    }),
    createWalletSuccess: (state, {payload}) => ({
      ...state,
      userWallet: payload,
      status: {
        ...successActionStatus,
        type: 'createWallet'
      }
    }),
    createWalletFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'createWallet'
      }
    }),
    getWallet: (state, {payload}) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getWallet'
      }
    }),
    getWalletSuccess: (state, {payload}) => ({
      ...state,
      userWallet: payload?.wallet,
      status: {
        ...successActionStatus,
        type: 'getWallet'
      }
    }),
    getWalletFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'getWallet'
      }
    }),
    updateWallet: (state, {payload}) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'updateWallet'
      }
    }),
    updateWalletSuccess: (state, {payload}) => ({
      ...state,
      userWallet: payload.wallet,
      status: {
        ...successActionStatus,
        type: 'updateWallet'
      }
    }),
    updateWalletFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'updateWallet'
      }
    }),
    reset: (state) => ({
      ...state,
      status: {
        ...resetActionStatus
      }
    })

  }
})

export default WalletSlice