const Options = () => {
  return (
    <div className="post-options">

      <div className="post-option">

        <svg className="post-option-icon icon-share">
          <use xlinkHref="#svg-share"/>
        </svg>

        <p className="post-option-text">Share</p>

      </div>

    </div>
  )
}

export default Options