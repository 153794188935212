import {all, call, put, takeLatest} from 'redux-saga/effects'
import AuthSlice from "../reducers/auth.reducer";
import FirebaseService from "../services/firebase.service";
import AccountService from '../services/account.service';

const {
  signInWithRedirect,
  signInWithRedirectFailed,
  signInWithRedirectSuccess,
  signInWithEmailAndPassword,
  signInWithEmailAndPasswordSuccess,
  signInWithEmailAndPasswordFailed,
  authInit,
  authInitSuccess,
  authInitFailed,
  signOut,
  signOutSuccess,
  signOutFailed,
  getUserAccount,
  getUserAccountSuccess,
  getUserAccountFailed,
  createUser,
  createUserSuccess,
  createUserFailed,
  registerUser,
  registerUserSuccess,
  registerUserFailed
} = AuthSlice.actions

function* signInWithRedirectSaga(action) {
  try {
    yield call(FirebaseService.signInWithRedirect, action.payload)
    // @ts-ignore
    // const result = yield call(FirebaseService.getRedirectResult, action.payload)
    yield put(signInWithRedirectSuccess())
  } catch (error) {
    yield put(signInWithRedirectFailed(error))
  }
}

function* authInitSaga() {
  try {
    yield call(FirebaseService.onAuthStateChanged, (userData) => {
      // console.log({userData})
    })
    const result = yield call(FirebaseService.getRedirectResult)
    yield put(authInitSuccess(result?.user))
  } catch (error) {
    yield put(authInitFailed(error))
  }
}

function* signOutSaga() {
  try {
    yield call(FirebaseService.signOut)
    yield put(signOutSuccess())
  } catch (error) {
    yield put(signOutFailed(error))
  }
}

function* createUserSaga(action) {
  try {
    const {user} = yield call(AccountService.createUser, action.payload)
    console.log({createUser: user})
    yield put(createUserSuccess(user))
    // yield put(registerUser(user))
    // yield put(getUserAccount({uid: user?.uid}))
  } catch (error) {
    yield put(createUserFailed(error))
  }
}

function* registerUserSaga(action) {
  try {
    yield call(AccountService.registerUser, action.payload)
    yield put(registerUserSuccess())
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* getUserAccountSaga(action) {
  try {
    const userAccount = yield call(AccountService.getUserAccount, action.payload)
    if (userAccount) {
      yield put(getUserAccountSuccess(userAccount))
    } else {
      yield put(getUserAccountFailed())
    }
  } catch (error) {
    yield put(getUserAccountFailed(error))
  }
}

function* signInWithEmailAndPasswordSaga(action) {
  try {
    const result = yield call(FirebaseService.signInWithEmailAndPassword, action.payload)
    yield put(signInWithEmailAndPasswordSuccess(result))
  } catch (error) {
    yield put(signInWithEmailAndPasswordFailed(error))
  }
}

export default function* root() {
  yield all([
    takeLatest(signInWithRedirect, signInWithRedirectSaga),
    takeLatest(authInit, authInitSaga),
    takeLatest(signOut, signOutSaga),
    takeLatest(registerUser, registerUserSaga),
    takeLatest(createUser, createUserSaga),
    takeLatest(getUserAccount, getUserAccountSaga),
    takeLatest(signInWithEmailAndPassword, signInWithEmailAndPasswordSaga),
  ])
}