import {createSlice} from "@reduxjs/toolkit";

import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";

const initialState = {
  hubSectionTitle: '',
  hubSubPageTitle: '',
  status: {
    ...initActionStatus
  }
}

const HubSlice = createSlice({
  name: 'Hub',
  initialState,
  reducers: {
    changeHubTitles: (state, {payload}) => ({
      ...state,
      hubSectionTitle: payload?.section,
      hubSubPageTitle: payload?.subpage,
    }),
  }
})

export default HubSlice