import {all, put, call, takeLatest} from 'redux-saga/effects'
import ProfilePostSlice from "../reducers/profile-post.reducer";
import ProfileService from "../services/profile.service";

const {
  submitPost,
  submitPostSuccess,
  submitPostFailed,
  getPosts,
  getPostsSuccess,
  getPostsFailed
} = ProfilePostSlice.actions

function* submitPostSaga(action) {
  try {
    const data = yield call(ProfileService.savePost, action.payload)
    yield put(submitPostSuccess(data))
  } catch (error) {
    yield put(submitPostFailed(error))
  }
}
function* getPostsSaga(action) {
  try {
    const data = yield call(ProfileService.getPosts, action.payload)
    yield put(getPostsSuccess(data))
  } catch (error) {
    yield put(getPostsFailed(error))
  }
}

export default function* root() {
  yield all([
    takeLatest(submitPost, submitPostSaga),
    takeLatest(getPosts, getPostsSaga),
  ])
}