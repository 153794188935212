import {createSlice} from '@reduxjs/toolkit'
import {orderBy} from 'lodash'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";

const initialState = {
  users: {},
  status: {
    ...resetActionStatus
  }
}

const ProfilePostSlice = createSlice({
  name: 'ProfilePost',
  initialState,
  reducers: {
    getPosts: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'getPosts'
        }
      }
    },
    getPostsSuccess: (state, {payload}) => {
      const posts = payload?.posts && Object.keys(payload?.posts).length ? Object.entries(payload?.posts)?.map(([id, item]) => ({id, ...item})) : []
      
      return {
        ...state,
        users: {
          ...state.users,
          [payload?.username]: {
            posts: orderBy(posts, ['date_created'], ['desc'])
          }
        },
        status: {
          ...successActionStatus,
          type: 'getPosts'
        }
      }
    },
    getPostsFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          message: payload?.message,
          type: 'getPosts'
        }
      }
    },
    
    submitPost: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'submitPost'
        }
      }
    },
    submitPostSuccess: (state, {payload}) => {
      const posts = orderBy([
        ...state.users[payload?.username]?.posts,
        payload?.post
      ], ['date_created'], ['desc'])
      
      return {
        ...state,
        users: {
          ...state.users,
          [payload?.username]: {
            posts
          }
        },
        status: {
          ...successActionStatus,
          type: 'submitPost'
        }
      }
    },
    submitPostFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          message: payload?.message,
          type: 'submitPost'
        }
      }
    },
    
    deletePost: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'deletePost'
        }
      }
    },
    deletePostSuccess: (state, {payload}) => {
      return {
        ...state,
       
        status: {
          ...successActionStatus,
          type: 'deletePost'
        }
      }
    },
    deletePostFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          message: payload?.message,
          type: 'deletePost'
        }
      }
    },
    
    reset: (state) => {
      return {
        ...state,
        status: {
          ...resetActionStatus,
        }
      }
    },
    
  }
})

export default ProfilePostSlice