import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import QuickPostFooter from "./quick-post-footer";
import QuickPostOptions from "./quick-post-options";
import ProfilePostSlice from "../../../../../shared/reducers/profile-post.reducer";

const QuickPost = () => {
  const dispatch = useDispatch()
  const {userAccount} = useSelector(state => state.Auth)
  const limit = 500
  const [charCount, setCharCount] = useState(0)
  const [postMessage, setPostMessage] = useState()
  const {submitPost} = ProfilePostSlice.actions
  const handleCharCount = (text) => {
    setPostMessage(text.target.value)
    setCharCount(text.target.value.length)
  }
  
  const resetForm = () => {
    setPostMessage('')
    setCharCount(0)
  }
  
  const handleSubmitPost = () => {
    dispatch(submitPost({
      userId: userAccount?.uid,
      username: userAccount?.profile?.username,
      photoURL: userAccount?.profile?.photoURL || '',
      message: postMessage
    }))
    resetForm()
  }

  return (
    <div className="quick-post">
      <div className="quick-post-header">
        <QuickPostOptions/>
      </div>
      
      <div className="quick-post-body">
        <form className="form" onSubmit={handleSubmitPost}>
          <div className="form-row">
            <div className="form-item">
              <div className="form-textarea">
                <textarea
                  maxLength={limit}
                  id="quick-post-text"
                  name="quick-post-text"
                  placeholder={`Hi ${userAccount?.profile?.name.split(' ')[0]}! Share your post here...`}
                  onChange={handleCharCount}
                  value={postMessage}
                />
                <p className="form-textarea-limit-text">{limit - charCount}/{limit}</p>
              </div>
            </div>
          </div>
        </form>
      </div>
      
      <QuickPostFooter handleSubmit={handleSubmitPost}/>
    </div>
  )
}

export default QuickPost