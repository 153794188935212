import {createSlice} from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";

const initialState = {
  leaderBoardList: [],
  status: {
    ...resetActionStatus
  }
}
const LeaderBoardSlice = createSlice({
  name: 'LeaderBoard',
  initialState,
  reducers: {
    getLeaderBoard: (state) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'getLeaderBoard'
        }
      }
    },
    getLeaderBoardSuccess: (state, {payload}) => {
      return {
        ...state,
        leaderBoardList: payload,
        status: {
          ...successActionStatus,
          type: 'getLeaderBoard'
        }
      }
    },
    getLeaderBoardFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'getLeaderBoard'
        }
      }
    },
  }
})

export default LeaderBoardSlice
