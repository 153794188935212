import {useEffect} from "react";
import useHexagon from "../../shared/hooks/hexagon.hook";
import appHelper from "../../shared/helpers/app.helper";

const ContactUs = () => {
  useHexagon()
  useEffect(() => {
    appHelper.querySelector('.form-input', function (elements) {
      for (const el of elements) {
        if (el.classList.contains('always-active')) continue;

        const input = el.querySelector('input'),
          textarea = el.querySelector('textarea'),
          activeClass = 'active';

        let inputItem = undefined;

        if (input) inputItem = input;
        if (textarea) inputItem = textarea;

        if (inputItem) {
          inputItem.addEventListener('focus', function () {
            el.classList.add(activeClass);
          });

          inputItem.addEventListener('blur', function () {
            if (inputItem.value === '') {
              el.classList.remove(activeClass);
            }
          });
        }
      }
    });

  }, [])
  return (
    <article className="post-open">
      <div style={{height: 250}}></div>
      <div className="post-open-body">
        <div className="post-open-heading">
          <h2 className="post-open-title">Contact Us</h2>
        </div>


        <div className="post-open-content">
          <div className="post-open-content-body" style={{width: '100%'}}>
            <form className="form">
              <div className="form-row split">
                <div className="form-item">
                  <div className="form-input small ">
                    <label htmlFor="account-full-name">Name</label>
                    <input type="text" id="account-full-name" name="account_full_name" defaultValue=""/>
                  </div>
                </div>
              </div>

              <div className="form-row split">
                <div className="form-item">
                  <div className="form-input small ">
                    <label htmlFor="account-email">Email</label>
                    <input type="text" id="account-email" name="account_email" defaultValue=""/>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-input small">
                    <label htmlFor="account-phone">Phone Number</label>
                    <input type="text" id="account-phone" name="account_phone" defaultValue=""/>
                  </div>
                </div>
              </div>
              <div className="form-row split">
                <div className="form-item">
                  <div className="form-input small full">
                <textarea id="profile-description" name="profile_description"
                          placeholder="What is your concern?">
                </textarea>
                  </div>
                </div>
              </div>
              <div className="form-row split">
                <div className="form-item">
                  <div className="form-input large full">
                    <button className="button primary">Send Email</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>


      </div>

    </article>
  )
}

export default ContactUs