import {Route, Routes} from "react-router-dom";
import React from "react";
import NotFound from "./404";
import Layout from "./layout";
import Server from "./server";

const ErrorPage = () => {
  return (
    <div className="error-section">
      <Routes>
        <Route element={<Layout/>}>
          <Route path="404" element={<NotFound/>}/>
          <Route path="500" element={<Server/>}/>
        </Route>
      </Routes>

    </div>
  )
}

export default ErrorPage