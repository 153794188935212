import {combineReducers} from '@reduxjs/toolkit';
import AuthSlice from './auth.reducer';
import AppSlice from "./app.reducer";
import HubSlice from "./hub.reducer";
import NotificationSlice from "./notification.reducer";
import AccountSlice from "./account.reducer";
import ChannelSlice from "./channel.reducer";
import ProfilePostSlice from "./profile-post.reducer";
import WalletSlice from "./wallet.reducer";

const rootReducer = combineReducers({
  App: AppSlice.reducer,
  Hub: HubSlice.reducer,
  Auth: AuthSlice.reducer,
  Account: AccountSlice.reducer,
  Notification: NotificationSlice.reducer,
  Channel: ChannelSlice.reducer,
  ProfilePost: ProfilePostSlice.reducer,
  Wallet: WalletSlice.reducer
})

export default rootReducer;