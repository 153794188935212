import React, { useEffect } from "react";
import appHelper from "../../../shared/helpers/app.helper";

const FloatyBar = () => {
  const renderProgressMobile = () => {
    appHelper.plugins.createProgressBar({
      container: "#logged-user-level-cp",
      height: 4,
      lineColor: "#5538b5",
    });

    appHelper.plugins.createProgressBar({
      container: "#logged-user-level-cp",
      height: 4,
      gradient: {
        colors: ["#40d04f", "#d9ff65"],
      },
      scale: {
        start: 0,
        end: 1000,
        stop: 242,
      },
      linkText: true,
      linkUnits: "exp",
      invertedProgress: true,
    });
  };
  useEffect(() => {
    renderProgressMobile();
  }, []);

  return (
    <aside className="floaty-bar">
      <div className="bar-actions">
        <div className="progress-stat">
          <div className="bar-progress-wrap">
            <p className="bar-progress-info">
              Next: <span className="bar-progress-text"></span>
            </p>
          </div>

          <div id="logged-user-level-cp" className="progress-stat-bar"></div>
        </div>
      </div>

      <div className="bar-actions">
        <div className="action-list dark">
          <a className="action-list-item" href="#">
            <svg className="action-list-item-icon icon-shopping-bag">
              <use xlinkHref="#svg-shopping-bag"></use>
            </svg>
          </a>

          <a className="action-list-item" href="#">
            <svg className="action-list-item-icon icon-revenue">
              <use xlinkHref="#svg-revenue"></use>
            </svg>
          </a>

          <a className="action-list-item" href="#">
            <svg className="action-list-item-icon icon-messages">
              <use xlinkHref="#svg-messages"></use>
            </svg>
          </a>

          <a className="action-list-item unread" href="#">
            <svg className="action-list-item-icon icon-notification">
              <use xlinkHref="#svg-notification"></use>
            </svg>
          </a>
        </div>

        <a className="action-item-wrap" href="#">
          <div className="action-item dark">
            <svg className="action-item-icon icon-settings">
              <use xlinkHref="#svg-settings"></use>
            </svg>
          </div>
        </a>
      </div>
    </aside>
  );
};

export default FloatyBar;
