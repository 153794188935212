import React from "react";
import {Link} from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <p className="error-section-title">404</p>

      <div className="error-section-info">
        <p className="error-section-subtitle">Oops!!...</p>

        <p className="error-section-text">Seems that you made a wrong turn and encountered a web black hole that absorbed
          the page you were looking for! But don't panic because you can go back!</p>

        <p className="error-section-text">If the problem persists, please <Link to="/contact-us">contact</Link> our support team</p>

        <Link className="button medium primary" to="/">Go Back</Link>
      </div>
    </>
  )
}

export default NotFound