import firebase from "./firebase.service";
import {
  getDownloadURL,
  getStorage,
  uploadString,
  ref as storageRef,
} from "firebase/storage";

import { remove as deleteData, update } from "firebase/database";

const { setData, get, ref, database } = firebase;

const AccountService = {
  registerUser: ({ user }) =>
    setData({ table: `accounts/${user.uid}`, data: user }),
  saveProfile: async ({ user }) => {
    const data = {
      name: user?.name,
      tagline: user?.tagline,
      description: user?.description,
      website: user?.website,
    };
    const accountsRef = ref(database, `accounts/${user?.uid}/profile`);
    await update(accountsRef, data);
  },
  saveSocial: async ({ userId, social }) => {
    await setData({ table: `accounts/${userId}/social`, data: social });
  },
  getUserAccount: async ({ uid }) => {
    const userAccountRef = await get(ref(database, `accounts/${uid}`));

    return userAccountRef.val();
  },
  getUsername: async ({ username }) => {
    const usernameRef = await get(ref(database, `usernames/${username}`));
    return usernameRef.val()
      ? { ...usernameRef.val(), username, existing: true }
      : { username, existing: false };
  },
  saveUsername: async ({ username, oldUsername, userId }) => {
    if (!username || !userId) throw new Error("Unable to change username");
    try {
      /*
       * We cannot overwrite the username so we have to delete and create a new entry with the new username
       * */
      await deleteData(ref(database, `usernames/${oldUsername}`));
    } catch (e) {
      await setData({
        table: `accounts/${userId}/profile/username`,
        data: userId,
      });
    }

    try {
      /*
       * Change username in usernames/:username
       * Change username in accounts/:uid/profile/username
       * */
      await setData({ table: `usernames/${username}`, data: { userId } });
      await setData({
        table: `accounts/${userId}/profile/username`,
        data: username,
      });

      /*
       * Change username in channels/:username
       * */
      const channelUsernameRef = await get(
        ref(database, `channels/${oldUsername}`)
      );
      if (channelUsernameRef.val()) {
        const channelData = {
          ...channelUsernameRef.val(),
          channelId: username,
        };
        await setData({ table: `channels/${username}`, data: channelData });
        await deleteData(ref(database, `channels/${oldUsername}`));
      }

      return username;
    } catch (e) {
      /*
       * Change username in usernames/:username
       * Change username in accounts/:uid/profile/username
       * */

      await setData({ table: `usernames/${userId}`, data: { userId } });
      await setData({
        table: `accounts/${userId}/profile/username`,
        data: userId,
      });

      return userId;
    }
  },
  createUser: ({ email, password }) => firebase.createUser({ email, password }),
  getProfile: async ({ user }) => {
    /*
     * Get profile access directly
     * */
    const userAccountRef = await get(
      ref(database, `accounts/${user?.uid}/profile`)
    );

    return userAccountRef.val();
  },
  uploadMedia: async ({ fileString, filename, user, type = "avatar" }) => {
    /*
     * Upload Cover or Photo
     * */
    const newFileName = `profiles/${user.uid}/${type}/${filename}`;
    const storage = getStorage();
    const uploadRef = await storageRef(storage, newFileName);
    await uploadString(uploadRef, fileString, "data_url");

    /*
     * Get the uploaded url and save it on accounts/:uid/profile/(photoURL|coverURL)
     * */
    const idRef = await storageRef(storage, newFileName);
    const fileUrl = await getDownloadURL(idRef);
    const avatar = { ...user, photoURL: fileUrl };
    const cover = { ...user, coverURL: fileUrl };
    await setData({
      table: `accounts/${user.uid}/profile/${
        type === "avatar" ? "photoURL" : "coverURL"
      }`,
      data: fileUrl,
    });

    return { type, ...(type === "avatar" ? avatar : cover) };
  },
};

export default AccountService;
