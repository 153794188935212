import {useEffect, useState} from "react";
import appHelper from "../../../shared/helpers/app.helper";
import {Link, useParams} from "react-router-dom";
import {useLocation, useMatch} from 'react-router'

const Navigation = () => {
  const params = useParams()
  const [username, setUsername] = useState()
  const location = useLocation()
  const match = useMatch('')

  useEffect(() => {
    appHelper.plugins.createSlider({
      container: '#section-navigation-slider',
      fixedWidth: 122,
      nav: false,
      loop: false,
      controlsContainer: '#section-navigation-slider-controls'
    });
  }, [])

  useEffect(() => {
    if (params?.username)
      setUsername(params?.username)
  }, [params]);

  return (
    <nav className="section-navigation">

      <div id="section-navigation-slider" className="section-menu">

        {/*<Link
          className={`section-menu-item ${location.pathname === `/channel/${username}/about` ? 'active' : ''}`}
          to={`/channel/${username}/about`}
        >
          <svg className="section-menu-item-icon icon-profile">
            <use xlinkHref="#svg-profile"/>
          </svg>
          <p className="section-menu-item-text">About</p>
        </Link>*/}


        <Link           
          className={`section-menu-item ${location.pathname === `/channel/${username}/news` ? 'active' : ''}`}
          to={`/channel/${username}/news`}
        >
          <svg className="section-menu-item-icon icon-quote">
            <use xlinkHref="#svg-quote"/>
          </svg>
          <p className="section-menu-item-text">News</p>
        </Link>


        {/* <Link className="section-menu-item" to={`/streamer/${username}/friends`}>
          <svg className="section-menu-item-icon icon-friend">
            <use xlinkHref="#svg-friend"/>
          </svg>
          <p className="section-menu-item-text">Friends</p>
        </Link>*/}


        {/*<Link className="section-menu-item" to={`/streamer/${username}/groups`}>
          <svg className="section-menu-item-icon icon-group">
            <use xlinkHref="#svg-group"/>
          </svg>

          <p className="section-menu-item-text">Groups</p>
        </Link>*/}


        {/*<Link className="section-menu-item" to={`/streamer/${username}/photos`}>
          <svg className="section-menu-item-icon icon-photos">
            <use xlinkHref="#svg-photos"/>
          </svg>
          <p className="section-menu-item-text">Photos</p>
        </Link>*/}

        {/*<Link className="section-menu-item" to={`/streamer/${username}/videos`}>
          <svg className="section-menu-item-icon icon-videos">
            <use xlinkHref="#svg-videos"/>
          </svg>
          <p className="section-menu-item-text">Videos</p>
        </Link>*/}


        {/*<Link className="section-menu-item" to={`/streamer/${username}/badges`}>
          <svg className="section-menu-item-icon icon-badges">
            <use xlinkHref="#svg-badges"/>
          </svg>
          <p className="section-menu-item-text">Badges</p>
        </Link>*/}

        <Link
          className={`section-menu-item ${location.pathname === `/channel/${username}/tournament` ? 'active' : ''}`}
          to={`/channel/${username}/tournament`}
        >
          <svg className="section-menu-item-icon icon-trophy">
            <use xlinkHref="#svg-trophy"/>
          </svg>
          <p className="section-menu-item-text">Tournament</p>
        </Link>

        {/*<Link
          className={`section-menu-item ${location.pathname === `/streamer/${username}/livestream` ? 'active' : ''}`}
          to={`/streamer/${username}/livestream`}
        >
          <svg className="section-menu-item-icon icon-streams">
            <use xlinkHref="#svg-streams"/>
          </svg>
          <p className="section-menu-item-text">Livestream</p>
        </Link>*/}


        {/*<Link className="section-menu-item" to={`/streamer/${username}/blog`}>
          <svg className="section-menu-item-icon icon-blog-posts">
            <use xlinkHref="#svg-blog-posts"/>
          </svg>
          <p className="section-menu-item-text">Blog</p>
        </Link>*/}


        {/*<Link className="section-menu-item" to={`/streamer/${username}/forum`}>
          <svg className="section-menu-item-icon icon-forum">
            <use xlinkHref="#svg-forum"/>
          </svg>
          <p className="section-menu-item-text">Forum</p>
        </Link>*/}


        {/*<Link className="section-menu-item" to={`/streamer/${username}/store`}>
          <svg className="section-menu-item-icon icon-store">
            <use xlinkHref="#svg-store"/>
          </svg>
          <p className="section-menu-item-text">Store</p>
        </Link>*/}

      </div>

      <div id="section-navigation-slider-controls" className="slider-controls">
        <div className="slider-control left">
          <svg className="slider-control-icon icon-small-arrow">
            <use xlinkHref="#svg-small-arrow"/>
          </svg>
        </div>
        <div className="slider-control right">
          <svg className="slider-control-icon icon-small-arrow">
            <use xlinkHref="#svg-small-arrow"/>
          </svg>
        </div>
      </div>

    </nav>
  )
}

export default Navigation