import firebase from "./firebase.service";
import {v4 as uuidv4} from "uuid";

const {
  setData,
  get,
  ref,
  database
} = firebase

const WalletService = {
  createWallet: async ({userId, wallet}) => {
    await setData({table: `wallets/${userId}`, data: wallet})
    return wallet
  },
  getWallet: async ({uid}) => {
    const walletRef = await get(ref(database, `wallets/${uid}`))
    return walletRef.val();
  },
  createTransaction: async ({userId, transaction}) => {
    const tnxId = uuidv4()
    const {action, amount, token} = transaction
    //action = add or minus
    //amount = float
    //token = usd / coupon / gem / energy

    const data = {
      id: tnxId,
      usd: 100,
      coupon: 0,
      gem: 0,
      energy: 1000
    }

    await setData({table: `wallets/${userId}/transactions/${tnxId}`, data})
  },
}

export default WalletService