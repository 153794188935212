import {Link} from "react-router-dom";
import React from "react";

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <div className="coming-soon-info">
        <p className="coming-soon-subtitle">Coming Soon</p>

        <p className="coming-soon-text">We are still working on this feature to make everything enjoyable.</p>

        <p className="coming-soon-text">Please <Link
          to="/contact-us">let us know</Link> what you think.</p>
      </div>
    </div>
  )
}
export default ComingSoon