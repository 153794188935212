import firebase from './firebase.service'
import {v4 as uuidv4} from 'uuid';

const {setData, updateData, getData, get, ref, database, auth} = firebase
const resource = 'tournaments'
const TournamentService = {
  postTournament: async ({tournament}) => {
    const tournamentId = uuidv4()
    const userId = auth?.currentUser?.uid;
    try {
      const userTournament = {
        [`${tournamentId}`]: true
      }
      await updateData({table: `accounts/${userId}/tournaments`, data: {...userTournament}})
      return await setData({table: `${resource}/${tournamentId}`, data: {...tournament, hostId: userId}})
    } catch (e) {
      return false;
    }
  },
  getTournamentList: async () => {
    const list = await get(ref(database, `tournaments`))
    return list.val();
  }
}

export default TournamentService