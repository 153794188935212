import React, {useEffect} from "react";
import useHeader from "../../shared/hooks/header.hook";
import Cart from "./components/cart/cart";
import Brand from "./components/header/brand";
import Navigation from "./components/header/navigation";
import Messages from "./components/messages/messages";
import Notifications from "./components/notifications/notifications";
import SearchBar from "./components/search/search-bar";
import Settings from "./components/settings/settings";
import Subscriptions from "./components/subscriptions/subscriptions";
import Progress from "./components/user/progress";
import appHelper from "../../shared/helpers/app.helper";
import {useDispatch} from "react-redux";
import AppSlice from "../../shared/reducers/app.reducer";
import useAuth from "../../shared/hooks/auth.hook";

const Header = () => {
  useAuth()
  useHeader();
  const dispatch = useDispatch()
  const {toggleMobileSidebar: toggleMenu} = AppSlice.actions
  const renderProgress = () => {
    appHelper.plugins.createProgressBar({
      container: '#logged-user-level',
      height: 4,
      lineColor: '#5538b5'
    });
    appHelper.plugins.createProgressBar({
      container: "#logged-user-level",
      height: 4,
      gradient: {
        colors: ["#40d04f", "#d9ff65"],
      },
      scale: {
        start: 0,
        end: 1000,
        stop: 500,
      },
      linkText: true,
      linkUnits: "coins",
      invertedProgress: true,
    });
  }

  useEffect(() => {
    renderProgress()
  }, []);

  const toggleMobileSidebar = () => {
    dispatch(toggleMenu())
  }

  return (
    <header className="header">
      <div className="header-actions">
        <Brand/>
      </div>
      <div className="header-actions">
        <div className="sidemenu-trigger navigation-widget-trigger">
          <svg className="icon-grid">
            <use xlinkHref="#svg-grid"/>
          </svg>
        </div>
        <div className="mobilemenu-trigger navigation-widget-mobile-trigger" onClick={toggleMobileSidebar}>
          <div className="burger-icon inverted">
            <div className="burger-icon-bar"/>
            <div className="burger-icon-bar"/>
            <div className="burger-icon-bar"/>
          </div>
        </div>
        <Navigation/>
      </div>
      <div className="header-actions search-bar">
        <SearchBar/>
      </div>
      <div className="header-actions">
        <Progress/>
      </div>
      <div className="header-actions">
        <div className="action-list hidden dark">
          <Cart/>
          <Subscriptions/>
          <Messages/>
          <Notifications/>
        </div>
        <Settings/>
      </div>
    </header>
  );
};
export default Header;
