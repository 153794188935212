import {all, put, call, takeLatest} from 'redux-saga/effects'
import WalletSlice from "../reducers/wallet.reducer";
import WalletService from "../services/wallet.service";

const {
  createWallet,
  createWalletSuccess,
  createWalletFailed,
  getWallet,
  getWalletSuccess,
  getWalletFailed,
  updateWallet,
  updateWalletSuccess,
  updateWalletFailed
} = WalletSlice.actions

function* createWalletSaga(action) {
  try {
    const data = yield call(WalletService.createWallet, action.payload)
    yield put(createWalletSuccess(data))
  } catch (e) {
    yield put(createWalletFailed(e))
  }
}

function* getWalletSaga(action) {
  try {
    const data = yield call(WalletService.getWallet, action.payload)
    if (data)
      yield put(getWalletSuccess(data))
    else
      throw new Error('No wallet found.')
  } catch (e) {
    yield put(getWalletFailed(e))
  }
}

function* updateWalletSaga(action) {
  try {
    const data = yield call(WalletService.updateWallet, action.payload)
    yield put(updateWalletSuccess(data))
  } catch (e) {
    yield put(updateWalletFailed(e))
  }
}


export default function* root() {
  yield all([
    takeLatest(createWallet, createWalletSaga),
    takeLatest(getWallet, getWalletSaga),
    takeLatest(updateWallet, updateWalletSaga),
  ])
}
