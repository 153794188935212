import {createSlice} from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus, successActionStatus
} from "../constants/status.constant";

const initialState = {
  channels: {},
  featuredChannels: {},
  status: {
    ...initActionStatus
  }
}

const ChannelSlice = createSlice({
  initialState,
  name: 'Channel',
  reducers: {
    createChannel: (state, {payload}) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'createChannel'
      }
    }),
    createChannelSuccess: (state, {payload}) => ({
      ...state,
      status: {
        ...successActionStatus,
        type: 'createChannel'
      }
    }),
    createChannelFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'createChannel'
      }
    }),
    getChannel: (state, {payload}) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getChannel'
      }
    }),
    getChannelSuccess: (state, {payload}) => ({
      ...state,
      channels: {
        ...state.channels,
        [payload?.username]: {
          ...state.channels[payload?.username],
          ...payload
        }
      },
      status: {
        ...successActionStatus,
        type: 'getChannel'
      }
    }),
    getChannelFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'getChannel'
      }
    }),
    getChannels: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getChannels'
      }
    }),
    getChannelsSuccess: (state, {payload}) => {
      return {
        ...state,
        channels: {
          ...payload
        },
        status: {
          ...successActionStatus,
          type: 'getChannels'
        }
      }
    },
    getChannelsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'getChannels'
      }
    }),
    getFeaturedChannels: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFeaturedChannels'
      }
    }),
    getFeaturedChannelsSuccess: (state, {payload}) => {
      return {
        ...state,
        featuredChannels: {
          ...Object.assign({}, ...payload)
        },
        status: {
          ...successActionStatus,
          type: 'getFeaturedChannels'
        }
      }
    },
    getFeaturedChannelsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload?.message,
        type: 'getFeaturedChannels'
      }
    }),
    reset: state => ({
      ...state,
      status: {
        ...initActionStatus
      }
    })
  }
})

export default ChannelSlice