import CommentList from './comment-list'

const Comments = () => {
  return (
    <div style={{marginBottom:20}}>
      <CommentList />
    </div>
  )
}

export default Comments
