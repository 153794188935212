const Brand = () => {
  return (
    <div className="header-brand">
      <div className="logo">
        <svg className="icon-logo small">
          <use xlinkHref="#svg-logo-eatsleepplay" />
        </svg>
      </div>
      <h1 className="header-brand-text">Eat Sleep Play</h1>
    </div>
  );
};

export default Brand;
