import {createSlice} from "@reduxjs/toolkit";
import {
  failedActionStatus,
  initActionStatus, resetActionStatus,
  successActionStatus
} from "../constants/status.constant";

const initialState = {
  notifications: [],
  status: {
    ...initActionStatus
  }
}
const NotificationSlice = createSlice({
  name: 'Notification',
  initialState,
  reducers: {
    getNotifications: (state) => ({
      ...state,
      notifications: [''],
      status: {
        type: 'getNotifications',
        ...initActionStatus
      }
    }),
    getNotificationsSuccess: (state, {payload}) => ({
      ...state,
      notifications: payload,
      status: {
        type: 'getNotifications',
        ...successActionStatus
      }
    }),
    getNotificationsFailed: (state, {payload}) => ({
      ...state,
      status: {
        type: 'getNotifications',
        message: payload?.message || 'Something went wrong',
        ...failedActionStatus
      }
    }),
    reset: (state) => ({
      ...state,
      status: {
        ...resetActionStatus
      }
    })
  }
})

export default NotificationSlice