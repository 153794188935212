import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import Post from "./components/timeline/post";
import QuickPost from "./components/timeline/post/quick-post";
import {useSelector} from "react-redux";
import Empty from "./components/timeline/post/empty";
import useOwner from "../../shared/hooks/owner.hook";

const Announcements = () => {
  const {username} = useParams()
  const {isOwner} = useOwner({username})
  return (
    <div className="grid mobile-prefer-content">
      <div className="grid-column">
        {
          isOwner ? (
              <QuickPost/>
            ) :
            ''
        }
        <Post/>
      </div>
    </div>
  )
}

export default Announcements