import React, {useRef, useEffect, useState} from 'react'
import Logo from '../../assets/img/logo/logo.png'
import {Spin} from "antd";
import {Spinner} from "../spinner";
import PageLoaderInfo from "./page-loader-info";

const PageLoader = () => {
  const pageLoaderRef = useRef()
  const [showPageLoader, setShowPageLoader] = useState(true)

  const hidePageLoader = () => {
    if (!pageLoaderRef) return
    setShowPageLoader(false)
  };

  useEffect(() => {
    setTimeout(() => {
      hidePageLoader()
    }, 5000)
    return () => {
      window.removeEventListener('load', hidePageLoader);
    }
  }, [])

  return showPageLoader ? (
    <div className="page-loader" ref={pageLoaderRef}>
      <PageLoaderInfo />
    </div>
  ) : ''
}
export default PageLoader