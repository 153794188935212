import React, {useEffect} from 'react'
import Header from "./header";
import Footer from "./footer";
import useSvgLoader from "../../shared/hooks/svg.hook";
import useContent from "../../shared/hooks/content.hook";
import useTooltips from "../../shared/hooks/tooltips.hook";
import {PageLoader} from "../../components/page-loader";
import useSidebar from "../../shared/hooks/sidebar.hook";

import Sidebar from "./sidebar";
import {useSelector} from "react-redux";
import styled from 'styled-components'

const ContentGrid = styled.div`
  &.transform {
    transform: translate(0, 0) !important;
  }
`

const Content = ({children}) => {
  // useContent()
  useSvgLoader()
  useTooltips()
  const {isContentFull} = useSelector(state => state.App)
  return (
    <>
      <Header/>
      <Sidebar/>
      <PageLoader/>
      <ContentGrid className={`content-grid ${isContentFull ? 'full transform' : ''}`} >
        {children}
      </ContentGrid>
      <Footer/>
    </>
  )
}

export default Content
