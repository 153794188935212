const Results = () => {
  return (
    <div className="widget-box no-padding">
      <div className="post-options">
        <div className="post-option">

          <svg className="post-option-icon icon-comment">
            <use xlinkHref="#svg-trophy"></use>
          </svg>

          <p className="post-option-text">Win</p>

        </div>
        <div className="post-option">

          <svg className="post-option-icon icon-comment">
            <use xlinkHref="#svg-return"></use>
          </svg>


          <p className="post-option-text">Draw</p>

        </div>
        <div className="post-option">

          <svg className="post-option-icon icon-share">
            <use xlinkHref="#svg-cross"></use>
          </svg>


          <p className="post-option-text">Lose</p>

        </div>
      </div>
    </div>
  )
}

export default Results