import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, get, child, update } from "firebase/database";
import {
  getAuth,
  signInWithRedirect,
  onAuthStateChanged,
  updateProfile,
  getRedirectResult,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_API_KEY,
  authDomain: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PRODUCTION_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const database = getDatabase();

const authProvider = {
  facebook: new FacebookAuthProvider(),
  google: new GoogleAuthProvider()
}

const FirebaseService = {
  app,
  get,
  ref,
  auth,
  update,
  database,
  analytics,
  currentUser: auth.currentUser,
  onAuthStateChanged: (callback) => onAuthStateChanged(auth, (userData) => {
    callback(userData)
  }),
  updateProfile: ({ payload, currentUser }) => updateProfile(currentUser, payload),
  signInWithRedirect: ({ provider }) => signInWithRedirect(auth, authProvider[provider]),
  signInWithEmailAndPassword: ({email, password}) => signInWithEmailAndPassword(auth, email, password),
  getRedirectResult: () => getRedirectResult(auth),
  getData: ({ table }) => get(child(ref(database), table)),
  setData: ({ table, data }) => set(ref(database, table), data),
  updateData: ({ table, data }) => update(ref(database, table), data),
  signOut: () => auth.signOut(),
  createUser: ({email, password}) => createUserWithEmailAndPassword(auth, email, password),
}


export default FirebaseService
