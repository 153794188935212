import {createSlice} from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";



const initialState = {
  tournamentList: [],
  status: {
    ...resetActionStatus
  }
}

const TournamentSlice = createSlice({
  name: 'Tournament',
  initialState,
  reducers: {
    getTournamentList: (state) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'getTournamentList',
        }
      }
    },

    getTournamentListSuccess: (state, {payload}) => {
      const tournamentList = Object.keys(payload)?.length ? Object.entries(payload)
        .map(([index, item]) => ({
          ...item,
          id: index,
        })) : []

      return {
        ...state,
        tournamentList,
        status: {
          ...successActionStatus,
          type: 'getTournamentList',
        }
      }
    },

    getTournamentListFailed: (state, {payload}) => {
      return {
        ...state,
        tournamentList: [],
        status: {
          ...failedActionStatus,
          message: payload,
          type: 'getTournamentList',
        }
      }
    },
    postTournament: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'postTournament',
        }
      }
    },

    postTournamentSuccess: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...successActionStatus,
          type: 'postTournament',
        }
      }
    },

    postTournamentFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          message: payload,
          type: 'postTournament',
        }
      }
    },

    resetStatus: (state) => ({
      ...state,
      status: {
        ...resetActionStatus
      }
    })
  }
})

export default TournamentSlice