import React from "react";
import SmallNav from "../../components/widgets/navigation/small";
import MobileNav from "../../components/widgets/navigation/mobile";
import DesktopNav from "../../components/widgets/navigation/desktop";

const Nav = () => {
  return (
    <>
      <SmallNav/>
      <MobileNav/>
      <DesktopNav/>
    </>
  );
};

export default Nav;
