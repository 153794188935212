import {all, put, call, takeLatest} from 'redux-saga/effects'
import ChannelSlice from "../reducers/channel.reducer";
import ChannelService from "../services/channel.service";
import AuthSlice from "../reducers/auth.reducer";

const {
  getChannel,
  getChannelSuccess,
  getChannelFailed,
  createChannel,
  createChannelSuccess,
  createChannelFailed,
  getChannels,
  getChannelsSuccess,
  getChannelsFailed,
  getFeaturedChannels,
  getFeaturedChannelsSuccess,
  getFeaturedChannelsFailed
} = ChannelSlice.actions

const {
  updateRole
} = AuthSlice.actions

function* getChannelSaga(action) {
  try {
    const data = yield call(ChannelService.getChannel, action.payload)
    yield put(getChannelSuccess(data))
  } catch (e) {
    yield put(getChannelFailed(e))
  }
}

function* getChannelsSaga(action) {
  try {
    const data = yield call(ChannelService.getChannels, action.payload)
    yield put(getChannelsSuccess(data))
  } catch (e) {
    yield put(getChannelsFailed(e))
  }
}

function* getFeaturedChannelsSaga(action) {
  try {
    const data = yield call(ChannelService.getChannels, action.payload)
    yield put(getFeaturedChannelsSuccess(data))
  } catch (e) {
    yield put(getFeaturedChannelsFailed(e))
  }
}

function* createChannelSaga(action) {
  try {
    const data = yield call(ChannelService.createChannel, action.payload)
    yield put(updateRole({role: 'channel'}))
    yield put(createChannelSuccess(data))
  } catch (e) {
    yield put(createChannelFailed(e))
  }
}

export default function* root() {
  yield all(
    [
      takeLatest(getChannel, getChannelSaga),
      takeLatest(getChannels, getChannelsSaga),
      takeLatest(getFeaturedChannels, getFeaturedChannelsSaga),
      takeLatest(createChannel, createChannelSaga)
    ]
  )
}