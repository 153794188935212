import React from "react";
import ReactPlayer from 'react-player'
import {PLAYER_CONFIG} from "../../../shared/constants/player.constant";
import cover from '../../../assets/img/cover/15.jpg'
import Results from "./predictions/results";
import {Link} from 'react-router-dom'
import Comments from "./predictions/comments";

const StreamVideo = () => {
  // const streamUrl = 'https://www.twitch.tv/gorgc'
  const streamUrl = 'https://www.facebook.com/VMGAXIE/videos/526039402895609/'

  return (
    <div className="stream-box big">
      <ReactPlayer
        url={streamUrl}
        width="100%"
        playing
        height="auto"
        className="stream-box-video"
        style={{minHeight: 200}}
        // light={cover}
        config={PLAYER_CONFIG}
      />
      <div className="stream-box-info">
        <a href={streamUrl} rel="nofollow" target="_blank">
          <figure className="stream-box-game-image liquid">
            <img src={cover} alt="cover-15"/>
          </figure>
        </a>
        <p className="stream-box-title">Raiden vs Terminator</p>

        <p className="stream-box-category">
          Game: &nbsp; Axie Infinity
        </p>
        <p className="stream-box-views">Current Round: <span
          style={{fontSize: 20, fontWeight: 700, color: '#8800ff'}}>11<sup>th</sup> round</span></p>
      </div>
      <Results/>
    </div>
  )
}

export default StreamVideo