import PageLoader from "../../components/page-loader/page-loader";
import {Outlet, useNavigate} from "react-router";
import React, {Suspense, useEffect, useState} from "react";
import Header from "./components/header";
import Navigation from "./components/navigation";
import useHexagon from "../../shared/hooks/hexagon.hook";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ChannelSlice from "../../shared/reducers/channel.reducer";

const Profile = () => {
  const dispatch = useDispatch()
  const {username} = useParams()
  const navigate = useNavigate()
  const [currentChannel, setCurrentChannel] = useState()
  const {getChannel} = ChannelSlice.actions
  
  const {channels, status} = useSelector(state => state.Channel)
  useEffect(() => {
    // console.log({username})
    getChannelData()
  }, [username])
  
  useEffect(() => {
    setCurrentChannel(channels[username])
  }, [channels])
  
  /*
  fetch the profile with the correct username
  * */
  const getChannelData = () => {
    dispatch(getChannel({username}))
  }
  
  useEffect(() => {
    if (status?.submitted && !status.loading) {
      switch (status?.type) {
        case 'getChannel':
          if (!status?.success) {
            navigate(`/error/404`)
          }
          break
      }
    }
  }, [status])
  
  return currentChannel ? (
    <Suspense fallback={<PageLoader/>}>
      <Header channel={currentChannel}/>
      <Navigation/>
      <Outlet/>
    </Suspense>
  ) : ''
}

export default Profile