import React from "react";

const Spinner = () => {
  return (
    <div className="page-loader-indicator loader-bars">
      <div className="loader-bar"/>
      <div className="loader-bar"/>
      <div className="loader-bar"/>
      <div className="loader-bar"/>
      <div className="loader-bar"/>
      <div className="loader-bar"/>
      <div className="loader-bar"/>
      <div className="loader-bar"/>
    </div>
  )
}

export default Spinner