/*
* This is where we setup the wallet
* Always go to this page when userWallet is null
* */

import React, {useEffect, useState} from 'react'
import Spinner from "../../components/spinner/spinner";
import {v4 as uuidv4} from "uuid";
import WalletSlice from "../../shared/reducers/wallet.reducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";

const Welcome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [message, setMessage] = useState()
  const [registrationAttempts, setRegistrationAttempts] = useState(0)
  const {getWallet, createWallet} = WalletSlice.actions
  const {userWallet, status: walletStatus} = useSelector(state => state.Wallet)
  const {status, currentUser, userAccount} = useSelector(state => state.Auth)
  const registrationLimit = 3
  
  useEffect(() => {
    setMessage('Logging in...')
    dispatch(getWallet({uid: currentUser?.uid}))
  }, [])
  
  useEffect(() => {
    if (!walletStatus?.loading && walletStatus?.submitted) {
      if (walletStatus?.type === 'createWallet') {
        
        if (walletStatus?.success) {
          navigate('/streams')
        }
        
        const canCreateWallet = registrationAttempts <= registrationLimit
        if (!walletStatus?.success && canCreateWallet) {
          registerWallet()
        }
        if (!walletStatus?.success && !canCreateWallet) {
          console.log('Sending error to developer')
        }
      }
      
      if (walletStatus?.type === 'getWallet' && !walletStatus?.success) {
        setMessage('Setting up your account...')
        registerWallet()
      } else {
        navigate('/streams')
      }
      
    }
  }, [walletStatus])
  
  const registerWallet = () => {
    if (currentUser && userWallet && Object.keys(userWallet)?.length) return
    setRegistrationAttempts(registrationAttempts + 1)
    const wallet = {
      active: true,
      balance: {
        usd: 0,
        energy: 0,
        coupon: 0,
        gem: 0,
        transaction: {
          [uuidv4()]: {
            action: 'add',
            amount: 5,
            token: 'gem'
          },
          [uuidv4()]: {
            action: 'add',
            amount: 1000,
            token: 'energy'
          }
        }
      }
    }
    dispatch(createWallet({userId: currentUser?.uid, wallet}))
  }
  
  return (
    <div className="welcome-section">
      <div>
        <h1 className="text-2xl text-center mb-5">
          {message}
        </h1>
        <Spinner/>
      </div>
    </div>
  )
}

export default Welcome