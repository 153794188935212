import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../shared/constants/routes.constant";
import NotificationSlice from "../../../../shared/reducers/notification.reducer";
import NotificationBox from "./notification-box";

const Notifications = () => {
  const dispatch = useDispatch();
  const { getNotifications } = NotificationSlice.actions;
  const { notifications } = useSelector((state) => state.Notification);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return (
    <div className="action-list-item-wrap">
      <div
        className="action-list-item unread header-dropdown-trigger"
        title="Notifications"
      >
        <svg className="action-list-item-icon icon-notification">
          <use xlinkHref="#svg-notification" />
        </svg>
      </div>
      <div className="dropdown-box header-dropdown">
        <div className="dropdown-box-header">
          <p className="dropdown-box-header-title">Notifications</p>
          <div className="dropdown-box-header-actions">
            <p className="dropdown-box-header-action">Mark all as Read</p>
            {/* <p className="dropdown-box-header-action">Settings</p> */}
          </div>
        </div>

        {notifications?.length ? (
          <div className="dropdown-box-list" data-simplebar>
            {notifications?.map((notification, index) => (
              <NotificationBox isRead={false} notification={{}} />
            ))}
          </div>
        ) : (
          ""
        )}

        <Link
          className="dropdown-box-button secondary"
          to={ROUTES.HUB.NOTIFICATIONS}
        >
          View all Notifications
        </Link>
      </div>
    </div>
  );
};

export default Notifications;
