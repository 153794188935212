import appHelper from "./app.helper";

export const formHelper = () => appHelper.querySelector('.form-input', function (elements) {
  for (const el of elements) {
    if (el.classList.contains('always-active')) continue;

    const input = el.querySelector('input'),
      textarea = el.querySelector('textarea'),
      activeClass = 'active';

    let inputItem = undefined;

    if (input) inputItem = input;
    if (textarea) inputItem = textarea;

    if (inputItem) {
      inputItem.addEventListener('focus', function () {
        el.classList.add(activeClass);
      });

      inputItem.addEventListener('blur', function () {
        if (inputItem.value === '') {
          el.classList.remove(activeClass);
        }
      });
    }
  }
});


export const isUsernameValid = (username) => {
  /*
    Usernames can only have:
    - Lowercase Letters (a-z)
    - Numbers (0-9)
    - Dots (.)
    - Underscores (_)
  */
  const res = /^[a-zA-Z0-9_]+$/.exec(username.trim());
  const valid = !!res;
  return valid;
}