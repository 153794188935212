import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

const useAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser } = useSelector(state => state.Auth)

  useEffect(() => {
    setTimeout(() => {
      if (!currentUser) {
        navigate(`/auth`)
      }
    })
  }, [location?.pathname, currentUser])

}

export default useAuth