import {useEffect} from "react";
import appHelper from "../../../../../shared/helpers/app.helper";
import {useDispatch} from "react-redux";

const Settings = ({post}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    let settings = appHelper.plugins.createDropdown({
      trigger: '.widget-box-post-settings-dropdown-trigger',
      container: '.widget-box-post-settings-dropdown',
      offset: {
        top: 30,
        right: 9
      },
      animation: {
        type: 'translate-top',
        speed: .3,
        translateOffset: {
          vertical: 20
        }
      }
    })
    return () => {
      settings = null
    }
  }, [])
  
  const deletePost = () => {
  }
  
  return (
    <div className="widget-box-settings">
      
      <div className="post-settings-wrap">
        
        <div className="post-settings widget-box-post-settings-dropdown-trigger">
          
          <svg className="post-settings-icon icon-more-dots">
            <use xlinkHref="#svg-more-dots"/>
          </svg>
        
        </div>
        
        
        <div className="simple-dropdown widget-box-post-settings-dropdown">
          <p className="simple-dropdown-link" onClick={deletePost}>Delete Post</p>
          <p className="simple-dropdown-link">Report Author</p>
        </div>
      
      </div>
    
    </div>
  )
}

export default Settings