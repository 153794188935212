const QuickPostFooter = ({handleSubmit}) => {
  return (
    <div className="quick-post-footer">
      {/*
      <div className="quick-post-footer-actions ">
        <div className="quick-post-footer-action text-tooltip-tft-medium" data-title="Insert Photo">
          <svg className="quick-post-footer-action-icon icon-camera">
            <use xlinkHref="#svg-camera"/>
          </svg>
        </div>

        <div className="quick-post-footer-action text-tooltip-tft-medium" data-title="Insert GIF">
          <svg className="quick-post-footer-action-icon icon-gif">
            <use xlinkHref="#svg-gif"/>
          </svg>
        </div>

        <div className="quick-post-footer-action text-tooltip-tft-medium" data-title="Insert Tag">
          <svg className="quick-post-footer-action-icon icon-tags">
            <use xlinkHref="#svg-tags"/>
          </svg>
        </div>
      </div>
    */}
      <div className="quick-post-footer-actions">
        <p className="button small secondary" onClick={handleSubmit}>Post</p>
      </div>
    </div>
  )
}

export default QuickPostFooter