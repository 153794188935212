import {useEffect} from "react";
import cover from '../../assets/img/cover/19.jpg'
import cover06 from '../../assets/img/cover/06.jpg'
import avatar from '../../assets/img/avatar/05.jpg'
import useHexagon from "../../shared/hooks/hexagon.hook";
import appHelper from "../../shared/helpers/app.helper";
import AppSlice from "../../shared/reducers/app.reducer";
import {useDispatch} from "react-redux";

const About = () => {
  const dispatch = useDispatch()
  useHexagon()
  const {toggleContentFull} = AppSlice.actions

  useEffect(() => {
    dispatch(toggleContentFull())

    appHelper.querySelector('.liquid', function (images) {
      for (const image of images) {
        appHelper.liquidify(image);
      }
    })
    return () => {
      dispatch(toggleContentFull())
    }
  }, [])
  return (
      <article className="post-open">
        <figure className="post-open-cover liquid">
          <img src={cover} alt="cover-19"/>
        </figure>

        <div className="post-open-body">

          <div className="post-open-heading">

            <p className="post-open-timestamp"><span className="highlighted">2 days ago</span>5 mins read</p>


            <h2 className="post-open-title">Here's the how and why did I became a full time Gaming Streamer</h2>

          </div>


          <div className="post-open-content">

            <div className="post-open-content-sidebar">

              <div className="user-avatar small no-outline">

                <div className="user-avatar-content">

                  <div className="hexagon-image-30-32" data-src={avatar}></div>

                </div>


                <div className="user-avatar-progress">

                  <div className="hexagon-progress-40-44"></div>

                </div>


                <div className="user-avatar-progress-border">

                  <div className="hexagon-border-40-44"></div>

                </div>


                <div className="user-avatar-badge">

                  <div className="user-avatar-badge-border">

                    <div className="hexagon-22-24"></div>

                  </div>


                  <div className="user-avatar-badge-content">

                    <div className="hexagon-dark-16-18"></div>

                  </div>


                  <p className="user-avatar-badge-text">24</p>

                </div>

              </div>


              <p className="post-open-sidebar-title">Share!</p>


              <div className="social-links vertical">

                <a className="social-link void facebook" href="#">

                  <svg className="icon-facebook">
                    <use xlinkHref="#svg-facebook"></use>
                  </svg>

                </a>


                <a className="social-link void twitter" href="#">

                  <svg className="icon-twitter">
                    <use xlinkHref="#svg-twitter"></use>
                  </svg>

                </a>

              </div>

            </div>


            <div className="post-open-content-body">

              <p className="post-open-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>


              <p className="post-open-paragraph">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
                sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse
                quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>


              <figure className="post-open-image liquid">
                <img src={cover06} alt="cover-06"/>
              </figure>


              <p className="post-open-image-caption">Elemental Witches is the first game with which I started
                streaming</p>


              <p className="post-open-paragraph">At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas estias excepturi sint
                occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est
                laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>


              <div className="tag-list">

                <a className="tag-item secondary" href="newsfeed.html">Streamer</a>


                <a className="tag-item secondary" href="newsfeed.html">Gaming</a>


                <a className="tag-item secondary" href="newsfeed.html">Job</a>


                <a className="tag-item secondary" href="newsfeed.html">Life</a>


                <a className="tag-item secondary" href="newsfeed.html">Experience</a>
              </div>
            </div>

          </div>


        </div>

      </article>
  )
}

export default About