import { createSlice } from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";


const initialState = {
  isShowMobileSidebar: false,
  isShowDesktopSidebar: false,
  isContentFull: false,
  status: {
    ...resetActionStatus
  }
}

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    toggleMobileSidebar: (state) => ({
      ...state,
      isShowMobileSidebar: !state.isShowMobileSidebar
    }),
    toggleContentFull: (state) => ({
      ...state,
      isContentFull: !state.isContentFull
    }),
  }
})

export default AppSlice
