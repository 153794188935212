import React, {Suspense} from 'react'
import TopBarProgress from "react-topbar-progress-indicator";

const SuspensedView = ({children}) => {
  // const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': '#b81d92',
    },
    barThickness: 4,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export default SuspensedView