import Logo from "../../assets/img/logo/logo.png";
import {Spinner} from "../spinner";
import React from "react";

const PageLoaderInfo = () => {
  return (
    <>
      <img src={Logo}/>
  
      <div className="page-loader-info">
        {/*<p className="page-loader-info-title">Eat Sleep Play</p>*/}
    
        <p className="page-loader-info-text">Loading...</p>
      </div>
  
      <Spinner/>
    </>
  )
}

export default PageLoaderInfo