import React, {Suspense, useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from "react-router";
import {useSelector} from "react-redux";
import Content from "./core/content";
import {PageLoader} from "../components/page-loader";
import {ROUTES} from "../shared/constants/routes.constant";

const MasterLayout = () => {
  const {userAccount} = useSelector(state => state.Auth)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if(!userAccount?.profile?.username){
      navigate(ROUTES.HUB.PROFILE_INFO)
    }
  }, [location.pathname])

  return (
    <Suspense fallback={<PageLoader/>}>
      <Content>
        <Outlet/>
      </Content>
    </Suspense>
  )
}

export default MasterLayout