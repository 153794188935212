const QuickPostOptions = () => {
  return (
    <div className="option-items">
      <div className="option-item active">
        <svg className="option-item-icon icon-status">
          <use xlinkHref="#svg-status"/>
        </svg>
        <p className="option-item-title">What's New?</p>
      </div>
      {/*<div className="option-item">
        <svg className="option-item-icon icon-blog-posts">
          <use xlinkHref="#svg-blog-posts"/>
        </svg>
        <p className="option-item-title">Blog Post</p>
      </div>
      <div className="option-item">
        <svg className="option-item-icon icon-poll">
          <use xlinkHref="#svg-poll"/>
        </svg>
        <p className="option-item-title">Poll</p>
      </div>*/}
    </div>
  )
}

export default QuickPostOptions