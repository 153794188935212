import { all, put, call, takeLatest } from "redux-saga/effects";
import NotificationSlice from "../reducers/notification.reducer";
import NotificationService from "../services/notification.service";

const { getNotifications, getNotificationsSuccess, getNotificationsFailed } =
  NotificationSlice.actions;
function* getNotificationsSaga(action) {
  try {
    const data = yield call(NotificationService.getNotifications);
    yield put(getNotificationsSuccess(data));
  } catch (e) {
    yield put(getNotificationsFailed(e));
  }
}

export default function* root() {
  yield all([takeLatest(getNotifications,getNotificationsSaga)]);
}
