import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const useOwner = ({username}) => {
  const [isOwner, setIsOwner] = useState()
  const {userAccount} = useSelector(state => state.Auth)
  
  useEffect(() => {
    if (username && userAccount?.profile?.username) {
      setIsOwner(
        username === userAccount?.profile?.username
      )
    }
  }, [username, userAccount?.profile?.username])

  return {isOwner}
}

export default useOwner