import Avatar01 from '../../../assets/img/avatar/01.jpg'
import {Link} from "react-router-dom";
import appHelper from "../../../shared/helpers/app.helper";
import {useEffect, useState} from "react";
import AppSlice from "../../../shared/reducers/app.reducer";
import {useDispatch, useSelector} from "react-redux";
import styled from 'styled-components'
import useHexagon from "../../../shared/hooks/hexagon.hook";
import AuthSlice from "../../../shared/reducers/auth.reducer";

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
  background-color: rgba(21, 21, 31, .96);
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
  }
`
const Mobile = () => {
  const dispatch = useDispatch()
  useHexagon()
  const {isShowMobileSidebar} = useSelector(state => state.App)
  const {toggleMobileSidebar} = AppSlice.actions
  const {signOut} = AuthSlice.actions
  const logOut = () => {
    dispatch(signOut())
  }
  useEffect(() => {
    appHelper.querySelector('.navigation-widget-mobile-trigger', function (el) {
      const navigationMobileTrigger = el[0],
        navigationWidgetMobile = document.querySelector('#navigation-widget-mobile'),
        navigationWidgetMobileCloseButton = navigationWidgetMobile.querySelector('.navigation-widget-close-button'),
        hiddenClass = 'hidden';

      // const overlay = document.createElement('div');
      //
      // overlay.style.width = '100%';
      // overlay.style.height = '100%';
      // overlay.style.position = 'fixed';
      // overlay.style.top = '0';
      // overlay.style.left = '0';
      // overlay.style.zIndex = 99998;
      // overlay.style.backgroundColor = 'rgba(21, 21, 31, .96)';
      // overlay.style.opacity = 0;
      // overlay.style.visibility = 'hidden';
      // overlay.style.transition = 'opacity .3s ease-in-out, visibility .3s ease-in-out';
      //
      // document.body.appendChild(overlay);

      const showOverlay = function () {
        overlay.style.opacity = 1;
        overlay.style.visibility = 'visible';
      };

      const hideOverlay = function () {
        overlay.style.opacity = 0;
        overlay.style.visibility = 'hidden';
      };

      const setNavigationWidgetMobileDimensions = function () {
        navigationWidgetMobile.style.height = `${window.innerHeight}px`;
      };

      const toggleNavigationWidgetMobile = function () {
        navigationWidgetMobile.classList.toggle(hiddenClass);

        const toggleOverlay = navigationWidgetMobile.classList.contains(hiddenClass) ? hideOverlay : showOverlay;
        toggleOverlay();
      };

      // navigationMobileTrigger.addEventListener('click', toggleNavigationWidgetMobile);
      // navigationWidgetMobileCloseButton.addEventListener('click', toggleNavigationWidgetMobile);
      // overlay.addEventListener('click', toggleNavigationWidgetMobile);

      setNavigationWidgetMobileDimensions();
      window.addEventListener('resize', setNavigationWidgetMobileDimensions);
    });
  }, [])

  const closeMobileSidebar = () => {
    dispatch(toggleMobileSidebar(false))
  }
  return (
    <>
      <Overlay className={isShowMobileSidebar ? 'active' : ''}/>
      <nav id="navigation-widget-mobile"
           className={`navigation-widget navigation-widget-mobile sidebar left ${!isShowMobileSidebar ? 'hidden' : 'visible'}`}
           data-simplebar>

        <div className="navigation-widget-close-button" onClick={closeMobileSidebar}>
          <svg className="navigation-widget-close-button-icon icon-back-arrow">
            <use xlinkHref="#svg-back-arrow"/>
          </svg>
        </div>


        <div className="navigation-widget-info-wrap">
          <div className="navigation-widget-info">
            <Link className="user-avatar small no-outline" to="profile-timeline.html">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={Avatar01}></div>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"></div>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"></div>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"></div>
                </div>

                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"></div>
                </div>

                <p className="user-avatar-badge-text">24</p>
              </div>
            </Link>

            <p className="navigation-widget-info-title">
              <Link to="profile-timeline.html">Marina Valentine</Link>
            </p>
            <p className="navigation-widget-info-text">Welcome Back!</p>
          </div>

          <p className="navigation-widget-info-button button small secondary" onClick={logOut}>Logout</p>
        </div>

        {/*<p className="navigation-widget-section-title">Sections</p>*/}
        <ul className="menu">
          {/*<li className="menu-item">
            <Link className="menu-item-link" to="/">
              <svg className="menu-item-link-icon icon-newsfeed">
                <use xlinkHref="#svg-newsfeed"/>
              </svg>
              Newsfeed
            </Link>
          </li>
*/}
          {/*<li className="menu-item">
            <Link className="menu-item-link" to="overview.html">
              <svg className="menu-item-link-icon icon-overview">
                <use xlinkHref="#svg-overview"/>
              </svg>
              Overview
            </Link>
          </li>*/}

          {/* <li className="menu-item">
            <Link className="menu-item-link" to="/guilds">
              <svg className="menu-item-link-icon icon-group">
                <use xlinkHref="#svg-group"/>
              </svg>
              Guilds
            </Link>
          </li>*/}

          {/*<li className="menu-item">
            <Link className="menu-item-link" to="members.html">
              <svg className="menu-item-link-icon icon-members">
                <use xlinkHref="#svg-members"/>
              </svg>
              Members
            </Link>
          </li>*/}

          {/*<li className="menu-item">
            <Link className="menu-item-link" to="badges.html">
              <svg className="menu-item-link-icon icon-badges">
                <use xlinkHref="#svg-badges"/>
              </svg>
              Badges
            </Link>
          </li>*/}

          {/*<li className="menu-item">
            <Link className="menu-item-link" to="quests.html">
              <svg className="menu-item-link-icon icon-quests">
                <use xlinkHref="#svg-quests"/>
              </svg>
              Quests
            </Link>
          </li>*/}

          <li className="menu-item active">
            <Link className="menu-item-link" to="/streams">
              <svg className="menu-item-link-icon icon-trophy">
                <use xlinkHref="#svg-trophy"/>
              </svg>
              Live Tournaments
            </Link>
          </li>

          {/*<li className="menu-item">
            <Link className="menu-item-link" to="/events">
              <svg className="menu-item-link-icon icon-events">
                <use xlinkHref="#svg-events"/>
              </svg>
              Events
            </Link>
          </li>*/}

          {/*<li className="menu-item">
            <Link className="menu-item-link" to="/forums">
              <svg className="menu-item-link-icon icon-forums">
                <use xlinkHref="#svg-forums"/>
              </svg>
              Forums
            </Link>
          </li>*/}

          {/*<li className="menu-item">
            <Link className="menu-item-link" to="marketplace">
              <svg className="menu-item-link-icon icon-marketplace">
                <use xlinkHref="#svg-marketplace"/>
              </svg>
              Marketplace
            </Link>
          </li>*/}

        </ul>

        <p className="navigation-widget-section-title">My Profile</p>
        <Link className="navigation-widget-section-link" to="/hub/profile/info">Profile Info</Link>
        <Link className="navigation-widget-section-link" to="/hub/profile/social">Social</Link>
        {/*<Link className="navigation-widget-section-link" to="hub-profile-messages.html">Messages</Link>*/}
        {/*<Link className="navigation-widget-section-link" to="hub-profile-requests.html">Friend Requests</Link>*/}
        <p className="navigation-widget-section-title">Account</p>
        <Link className="navigation-widget-section-link" to="/hub/account/info">Account Info</Link>
        <Link className="navigation-widget-section-link" to="/hub/account/notifications">Notifications</Link>
        {/*<Link className="navigation-widget-section-link" to="hub-account-password.html">Change Password</Link>*/}
        {/*<Link className="navigation-widget-section-link" to="hub-account-settings.html">General Settings</Link>*/}
        {/*
        <p className="navigation-widget-section-title">Guild</p>
        <Link className="navigation-widget-section-link" to="hub-group-management.html">Manage Guild</Link>
        <Link className="navigation-widget-section-link" to="hub-group-invitations.html">Invitations</Link>
*/}

        {/*    <p className="navigation-widget-section-title">My Store</p>

        <Link className="navigation-widget-section-link" to="hub-store-account.html">My Account <span
          className="highlighted">$250,32</span></Link>
        <Link className="navigation-widget-section-link" to="hub-store-statement.html">Sales Statement</Link>
        <Link className="navigation-widget-section-link" to="hub-store-items.html">Manage Items</Link>
        <Link className="navigation-widget-section-link" to="hub-store-downloads.html">Downloads</Link>
*/}
        <p className="navigation-widget-section-title">Main Links</p>
        <Link className="navigation-widget-section-link" to="#">Home</Link>
        <Link className="navigation-widget-section-link" to="#">Careers</Link>
        <Link className="navigation-widget-section-link" to="#">Faqs</Link>
        <Link className="navigation-widget-section-link" to="#">About Us</Link>
        <Link className="navigation-widget-section-link" to="#">Our Blog</Link>
        <Link className="navigation-widget-section-link" to="#">Contact Us</Link>
        <Link className="navigation-widget-section-link" to="#">Privacy Policy</Link>
      </nav>
    </>
  )
}

export default Mobile