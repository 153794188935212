import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import moment from "moment-timezone";
import Settings from "./settings";
import Options from "./options";
import Actions from "./actions";
import ProfilePostSlice from "../../../../../shared/reducers/profile-post.reducer";
import useHexagon from "../../../../../shared/hooks/hexagon.hook";
import Empty from "./empty";

const Post = () => {
  const dispatch = useDispatch()
  const {username} = useParams()
  const {channels, status} = useSelector(state => state.Channel)
  const {users} = useSelector(state => state.ProfilePost)
  const {getPosts} = ProfilePostSlice.actions
  
  useEffect(() => {
    dispatch(
      getPosts({
        username
      })
    )
  }, []);
  useHexagon()
  
  return (
    users[username]?.posts?.length ? users[username]?.posts?.map(post => (
      <div className="widget-box no-padding" key={post.id}>
        <Settings post={post}/>
        <div className="widget-box-status">
          <div className="widget-box-status-content pb-3">
            
            <div className="user-status">
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div className="hexagon-image-30-32" data-src={post?.author?.photoURL}/>
                  </div>
                  
                  <div className="user-avatar-progress">
                    <div className="hexagon-progress-40-44"/>
                  </div>
                  
                  <div className="user-avatar-progress-border">
                    <div className="hexagon-border-40-44"/>
                  </div>
                  
                  <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                      <div className="hexagon-22-24"/>
                    </div>
                    
                    <div className="user-avatar-badge-content">
                      <div className="hexagon-dark-16-18"/>
                    </div>
                    {/*<p className="user-avatar-badge-text">24</p>*/}
                  </div>
                </div>
              </div>
              
              <p className="user-status-title medium">
                <span className="bold">
                  @{post?.author?.username}
                </span>
              </p>
              <p className="user-status-text small" title={moment(post?.date_created).format('MMM DD, YYYY @ hh:mm a')}>
                {moment(post?.date_created).fromNow()}
              </p>
            </div>
            
            <p className="widget-box-status-text">{post?.message}</p>
            
            {/*<Actions/>*/}
            <div className="my-2"/>
          </div>
        </div>
        <Options/>
      </div>
    )) : <Empty/>
  
  )
}

export default Post
