import React from "react";

const Empty = () => {
  return (
    <div className="widget-box no-padding">
      <div className="widget-box-status">
        <div className="widget-box-status-content">
          <p className="widget-box-status-text">Nothing to show</p>
          <div className="my-5"/>
        </div>
      </div>
    </div>
  )
}
export default Empty