import {all, fork} from 'redux-saga/effects'
import Auth from './auth.saga';
import Account from './account.saga';
import LeaderBoard from './leader-board.saga';
import Tournament from './tournament.saga';
import Channel from './channel.saga';
import ProfilePost from './profile-post.saga';
import Wallet from './wallet.saga';
import Notifications from './notification.saga';

export default function* root() {
  yield all([
    fork(Auth),
    fork(Account),
    fork(LeaderBoard),
    fork(Tournament),
    fork(Channel),
    fork(ProfilePost),
    fork(Notifications),
    fork(Wallet)
  ])
}
