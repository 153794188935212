import React, {useEffect} from "react";
import appHelper from "../../shared/helpers/app.helper";
import useFormUtility from "../../shared/hooks/form-utility.hook";
import useAuth from "../../shared/hooks/auth.hook";
import Register from "./register";
import Login from "./login";
import useRegistration from "../../shared/hooks/registration.hook";
import useSvgLoader from "../../shared/hooks/svg.hook";
import logo from '../../assets/images/eatsleepplay-lg.png'
import {formHelper} from "../../shared/helpers/form.helper";

const Auth = () => {
  useSvgLoader()
  useEffect(() => {
    formHelper()
    appHelper.querySelector('.form-switch', function (switchInputs) {
      for (const switchInput of switchInputs) {
        const toggleSwitch = function () {
          switchInput.classList.toggle('active');
        };

        switchInput.addEventListener('click', toggleSwitch);
      }
    });
    // appHelper.plugins.createTab({
    //   triggers: '.login-register-form-trigger',
    //   elements: '.login-register-form-element',
    //   animation: {
    //     type: 'slide-in-right'
    //   },
    //   onTabChange: function (activeTab) {
    //     const firstInput = activeTab.querySelector('input');
    //     firstInput?.focus();
    //   }
    // });
  }, [])

  return (
    <div className="landing">
      <div className="landing-decoration"/>
      <div className="landing-info">

        <h2 className="landing-info-pretitle">Welcome to</h2>
        <h1 className="landing-info-title">Eat • Sleep • Play</h1>

        <p className="landing-info-text">The next generation social network &amp; community! Connect with your friends
          and play with our quests and badges gamification system!</p>
        {status?.loading && status?.type === 'authInit' ? (
          'Authenticating...'
        ) : (
          <div className="tab-switch">
            {/*<p className="tab-switch-button login-register-form-trigger">Login</p>*/}
            {/*<p className="tab-switch-button login-register-form-trigger">Register</p>*/}
          </div>
        )}

      </div>

      <div className="landing-form">
        <Login/>
        {/*<Register/>*/}
      </div>
    </div>
  )
}

export default Auth