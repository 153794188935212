import {useEffect} from "react";
import appHelper from "../helpers/app.helper";

const useTooltips = () => {
  useEffect(() => {
    appHelper.plugins.createTooltip({
      container: '.text-tooltip-tfr',
      offset: 4,
      direction: 'right',
      animation: {
        type: 'translate-in-fade'
      }
    });

    appHelper.plugins.createTooltip({
      container: '.text-tooltip-tft',
      offset: 4,
      direction: 'top',
      animation: {
        type: 'translate-out-fade'
      }
    });

    appHelper.plugins.createTooltip({
      container: '.text-tooltip-tft-medium',
      offset: 8,
      direction: 'top',
      animation: {
        type: 'translate-out-fade'
      }
    });
  }, []);
}

export default useTooltips