import {createSlice} from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";
import {GoogleAuthProvider} from "firebase/auth";
import cover from '../../assets/img/cover/05.jpg'
import photo from '../../assets/img/avatar/08.jpg'

const initialState = {
  currentUser: null,
  userAccount: null,
  cover,
  photo,
  status: {
    ...resetActionStatus
  }
}

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    signInWithRedirect: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'signInWithRedirect',
          ...initActionStatus
        }
      }
    },
    signInWithRedirectSuccess: (state, {payload}) => {
      const credential = GoogleAuthProvider.credentialFromResult(payload);
      const token = credential.accessToken;
      
      const currentUser = payload.user;
      
      return {
        ...state,
        currentUser,
        token,
        status: {
          type: 'signInWithRedirect',
          ...successActionStatus
        }
      }
    },
    signInWithRedirectFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'signInWithRedirect',
          ...failedActionStatus
        }
      }
    },
    authInit: (state) => {
      return {
        ...state,
        status: {
          type: 'authInit',
          ...initActionStatus
        }
      }
    },
    authInitSuccess: (state, {payload}) => {
      return {
        ...state,
        currentUser: payload || state.currentUser,
        status: {
          type: 'authInit',
          ...successActionStatus
        }
      }
    },
    authInitFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'authInit',
          message: payload?.message || payload,
          ...failedActionStatus
        }
      }
    },
    signOut: (state) => {
      return {
        ...state,
        status: {
          type: 'signOut',
          ...initActionStatus
        }
      }
    },
    signOutSuccess: (state) => {
      localStorage.clear()
      return {
        ...state,
        currentUser: null,
        userAccount: null,
        status: {
          type: 'signOut',
          ...successActionStatus
        }
      }
    },
    signOutFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'signOut',
          ...failedActionStatus
        }
      }
    },
    getUserAccount: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'getUserAccount',
          ...initActionStatus
        }
      }
    },
    getUserAccountSuccess: (state, {payload}) => {
      return {
        ...state,
        userAccount: payload,
        status: {
          type: 'getUserAccount',
          ...successActionStatus
        }
      }
    },
    getUserAccountFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'getUserAccount',
          ...failedActionStatus
        }
      }
    },
    createUser: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'createUser',
          ...initActionStatus
        }
      }
    },
    createUserSuccess: (state, {payload}) => {
      const currentUser = payload;
      
      return {
        ...state,
        currentUser,
        status: {
          type: 'createUser',
          ...successActionStatus
        }
      }
    },
    createUserFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'createUser',
          ...failedActionStatus
        }
      }
    },
    updateRole: (state, {payload}) => {
      return {
        ...state,
        userAccount: {
          ...state.userAccount,
          account: {
            ...state.userAccount.account,
            role: payload?.role
          }
        }
      }
    },
    updateUser: (state, {payload}) => {
      return {
        ...state,
        userAccount: {
          ...state.userAccount,
          profile: {
            ...state.userAccount.profile,
            ...payload?.user
          }
        }
      }
    },
    updateUserMedia: (state, {payload}) => {
      return {
        ...state,
        userAccount: {
          ...state.userAccount,
          profile: {
            ...state.userAccount.profile,
            ...payload.uploadedMedia
          }
        }
      }
    },
    updateUserSocial: (state, {payload}) => {
      return {
        ...state,
        userAccount: {
          ...state.userAccount,
          social: payload
        }
      }
    },
    registerUser: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'registerUser',
          ...initActionStatus
        }
      }
    },
    registerUserSuccess: (state) => {
      return {
        ...state,
        status: {
          type: 'registerUser',
          ...successActionStatus
        }
      }
    },
    registerUserFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'registerUser',
          ...failedActionStatus
        }
      }
    },
    signInWithEmailAndPassword: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'signInWithEmailAndPassword',
          ...initActionStatus
        }
      }
    },
    signInWithEmailAndPasswordSuccess: (state, {payload}) => {
      // const credential: any = GoogleAuthProvider.credentialFromResult(payload);
      const token = payload?.accessToken;
      const currentUser = payload?.user;
      return {
        ...state,
        currentUser,
        token,
        status: {
          type: 'signInWithEmailAndPassword',
          ...successActionStatus
        }
      }
    },
    signInWithEmailAndPasswordFailed: (state, {payload}) => {
      return {
        ...state,
        status: {
          type: 'signInWithEmailAndPassword',
          ...failedActionStatus
        }
      }
    },
    reset: (state) => {
      return {
        ...state,
        status: {
          ...initActionStatus
        }
      }
    }
  }
})

export default AuthSlice
