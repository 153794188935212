import Dropdown from "./dropdown";

const Settings = () => {
  return (
    <div className="action-item-wrap">
      <div className="action-item dark header-settings-dropdown-trigger">
        <svg className="action-item-icon icon-settings">
          <use xlinkHref="#svg-settings"/>
        </svg>
      </div>
      <Dropdown/>
    </div>
  )
}

export default Settings