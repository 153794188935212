import {Link} from 'react-router-dom'

const Navigation = () => {
  return (
    <nav className="navigation">
      <ul className="menu-main">
        <li className="menu-main-item"><Link className="menu-main-item-link" to="/streams">Home</Link></li>
        <li className="menu-main-item"><Link className="menu-main-item-link" to="/about">About Us</Link></li>
        <li className="menu-main-item"><Link className="menu-main-item-link" to="/contact-us">Contact Us</Link></li>
        {/*<li className="menu-main-item"><Link className="menu-main-item-link" to="#">Careers</Link></li>*/}
        {/*<li className="menu-main-item"><Link className="menu-main-item-link" to="#">Faqs</Link></li>*/}
        {/*
        <li className="menu-main-item">
          <p className="menu-main-item-link">
            <svg className="icon-dots">
              <use xlinkHref="#svg-dots"/>
            </svg>
          </p>
          <ul className="menu-main">
            <li className="menu-main-item"><Link className="menu-main-item-link" to="#">Our Blog</Link></li>
            <li className="menu-main-item"><Link className="menu-main-item-link" to="#">Privacy Policy</Link></li>
          </ul>
        </li>
        */}
      </ul>
    </nav>
  )
}
export default Navigation