import React from "react";
import {Link} from "react-router-dom";

const Server = () => {
  return (
    <>
      <p className="error-section-title">500</p>

      <div className="error-section-info">
        <p className="error-section-subtitle">Oops!!...</p>

        <p className="error-section-text">Seems that the server has encountered an error! Our engineers are currently working on it. But don't panic because you can go back!</p>

        <p className="error-section-text">If the problem persists, please <Link to="/contact-us">contact</Link> our support team</p>

        <Link classNameName="button medium primary" to="/">Go Back</Link>
      </div>
    </>
  )
}
export default Server