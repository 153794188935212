import {useEffect} from "react";
import appHelper from "../helpers/app.helper";

const useSidebar = () => {
  useEffect(() => {
    /*-------------------
    CONTENT GRID
-------------------*/
    appHelper.querySelector('.content-grid', function (el) {
      const sidebar = {
          chat: {
            active: false,
            minWidth: 80,
            maxWidth: 300
          },
          navigation: {
            active: false,
            minWidth: 80,
            maxWidth: 300
          }
        },
        breakpointWidth = 1366;

      const updateGridPosition = function (contentGrid) {
        if (window.innerWidth > breakpointWidth) {
          const chatWidth = sidebar.chat.active ? sidebar.chat.maxWidth : sidebar.chat.minWidth,
            navigationWidth = sidebar.navigation.active ? sidebar.navigation.maxWidth : sidebar.navigation.minWidth,
            availableWidth = document.body.clientWidth - contentGrid.offsetWidth - chatWidth - navigationWidth,
            offsetX = (availableWidth / 2) + navigationWidth;

          contentGrid.style.transform = `translate(${offsetX}px, 0)`;
        } else {
          contentGrid.style.transform = `translate(0, 0)`;
        }
      };

      const updateGridPositions = function () {
        for (const grid of el) {
          updateGridPosition(grid);
        }
      };

      const setGridTransition = function (grid) {
        grid.style.transition = `transform .4s ease-in-out`;
      };

      const setGridTransitions = function () {
        for (const grid of el) {
          setGridTransition(grid);
        }
      };

      updateGridPositions();
      window.addEventListener('resize', updateGridPositions);
      // delay transition setup to avoid loading animation
      window.setTimeout(setGridTransitions, 300);

      /*-------------------
          CHAT WIDGET
      -------------------*/
      appHelper.querySelector('#chat-widget-messages', function (el) {
        const chatWidget = el[0],
          topOffset = 80,
          closedWidth = 80,
          openWidth = 300,
          chatWidgetMessages = chatWidget.querySelector('.chat-widget-messages'),
          chatWidgetForm = chatWidget.querySelector('.chat-widget-form'),
          chatWidgetButton = chatWidget.querySelector('.chat-widget-button'),
          closedClass = 'closed';

        const setChatMessagesDimensions = function () {
          if (chatWidget.classList.contains(closedClass)) {
            chatWidgetMessages.style.height = `${window.innerHeight - chatWidgetButton.offsetHeight - topOffset}px`;
          } else {
            chatWidgetMessages.style.height = `${window.innerHeight - chatWidgetForm.offsetHeight - chatWidgetButton.offsetHeight - topOffset}px`;
          }
        };

        const toggleChatWidget = function () {
          chatWidget.classList.toggle(closedClass);
          setChatMessagesDimensions();

          sidebar.chat.active = !chatWidget.classList.contains(closedClass);
          updateGridPositions();
        };

        const openChatWidget = function () {
          chatWidget.classList.remove(closedClass);
          setChatMessagesDimensions();

          sidebar.chat.active = true;
          updateGridPositions();
        };

        chatWidgetButton.addEventListener('click', toggleChatWidget);

        setChatMessagesDimensions();
        window.addEventListener('resize', setChatMessagesDimensions);

        appHelper.querySelector('#chat-widget-message', function (el) {
          const chatMessageWidget = el[0],
            chatWidgetMessage = chatWidgetMessages.querySelectorAll('.chat-widget-message'),
            chatMessageWidgetHeader = chatMessageWidget.querySelector('.chat-widget-header'),
            chatMessageWidgetConversation = chatMessageWidget.querySelector('.chat-widget-conversation'),
            chatMessageWidgetCloseButton = chatMessageWidget.querySelector('.chat-widget-close-button'),
            hiddenClass = 'hidden';

          const setChatConversationDimensions = function () {
            chatMessageWidgetConversation.style.height = `${window.innerHeight - chatMessageWidgetHeader.offsetHeight - chatWidgetForm.offsetHeight - chatWidgetButton.offsetHeight - topOffset}px`;
          };

          const toggleChatMessageWidget = function () {
            chatMessageWidget.classList.toggle(hiddenClass);
          };

          const closeChatMessageWidget = function () {
            chatMessageWidget.classList.add(hiddenClass);
          };

          for (const widgetMessage of chatWidgetMessage) {
            widgetMessage.addEventListener('click', toggleChatMessageWidget);
            widgetMessage.addEventListener('click', openChatWidget);
          }

          chatWidgetButton.addEventListener('click', closeChatMessageWidget);
          chatMessageWidgetCloseButton.addEventListener('click', toggleChatMessageWidget);

          setChatConversationDimensions();
          window.addEventListener('resize', setChatConversationDimensions);
        });
      });

      /*------------------------
          NAVIGATION WIDGET
      ------------------------*/
/*
      appHelper.querySelector('.navigation-widget-trigger', function (el) {
        const navigationTrigger = el[0],
          topOffset = 80,
          navigationWidget = document.querySelector('#navigation-widget'),
          navigationWidgetSmall = document.querySelector('#navigation-widget-small'),
          activeClass = 'active',
          hiddenClass = 'hidden',
          delayedClass = 'delayed';

        const setNavigationWidgetDimensions = function () {
          navigationWidget.style.height = `${window.innerHeight - topOffset}px`;
        };

        const toggleNavigationWidget = function () {
          navigationTrigger.classList.toggle(activeClass);

          navigationWidget.classList.toggle(delayedClass);
          navigationWidget.classList.toggle(hiddenClass);
          navigationWidgetSmall.classList.toggle(delayedClass);
          navigationWidgetSmall.classList.toggle(hiddenClass);

          sidebar.navigation.active = !navigationWidget.classList.contains(hiddenClass);
          updateGridPositions();
        };

        navigationTrigger.addEventListener('click', toggleNavigationWidget);

        setNavigationWidgetDimensions();
        window.addEventListener('resize', setNavigationWidgetDimensions);
      });
*/

      /*-------------------------------
          NAVIGATION WIDGET MOBILE
      -------------------------------*/
/*
      appHelper.querySelector('.navigation-widget-mobile-trigger', function (el) {
        const navigationMobileTrigger = el[0],
          navigationWidgetMobile = document.querySelector('#navigation-widget-mobile'),
          navigationWidgetMobileCloseButton = navigationWidgetMobile.querySelector('.navigation-widget-close-button'),
          hiddenClass = 'hidden';

        const overlay = document.createElement('div');

        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.zIndex = 99998;
        overlay.style.backgroundColor = 'rgba(21, 21, 31, .96)';
        overlay.style.opacity = 0;
        overlay.style.visibility = 'hidden';
        overlay.style.transition = 'opacity .3s ease-in-out, visibility .3s ease-in-out';

        document.body.appendChild(overlay);

        const showOverlay = function () {
          overlay.style.opacity = 1;
          overlay.style.visibility = 'visible';
        };

        const hideOverlay = function () {
          overlay.style.opacity = 0;
          overlay.style.visibility = 'hidden';
        };

        const setNavigationWidgetMobileDimensions = function () {
          navigationWidgetMobile.style.height = `${window.innerHeight}px`;
        };

        const toggleNavigationWidgetMobile = function () {
          navigationWidgetMobile.classList.toggle(hiddenClass);

          const toggleOverlay = navigationWidgetMobile.classList.contains(hiddenClass) ? hideOverlay : showOverlay;
          toggleOverlay();
        };

        navigationMobileTrigger.addEventListener('click', toggleNavigationWidgetMobile);
        navigationWidgetMobileCloseButton.addEventListener('click', toggleNavigationWidgetMobile);
        overlay.addEventListener('click', toggleNavigationWidgetMobile);

        setNavigationWidgetMobileDimensions();
        window.addEventListener('resize', setNavigationWidgetMobileDimensions);
      });
*/

      appHelper.plugins.createHexagon({
        container: '.hexagon-148-164',
        width: 148,
        height: 164,
        roundedCorners: true,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-progress-124-136',
        width: 124,
        height: 136,
        lineWidth: 8,
        roundedCorners: true,
        gradient: {
          colors: ['#d9ff65', '#40d04f']
        },
        scale: {
          start: 0,
          end: 1,
          stop: .74
        }
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-border-124-136',
        width: 124,
        height: 136,
        lineWidth: 8,
        roundedCorners: true,
        lineColor: '#293249'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-100-110',
        width: 100,
        height: 110,
        roundedCorners: true,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-120-132',
        width: 120,
        height: 132,
        roundedCorners: true,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-progress-100-110',
        width: 100,
        height: 110,
        lineWidth: 6,
        roundedCorners: true,
        gradient: {
          colors: ['#d9ff65', '#40d04f']
        },
        scale: {
          start: 0,
          end: 1,
          stop: .8
        }
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-border-100-110',
        width: 100,
        height: 110,
        lineWidth: 6,
        roundedCorners: true,
        lineColor: '#293249'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-82-90',
        width: 82,
        height: 90,
        roundedCorners: true,
        roundedCornerRadius: 3,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-100-110',
        width: 100,
        height: 110,
        roundedCorners: true,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-progress-84-92',
        width: 84,
        height: 92,
        lineWidth: 5,
        roundedCorners: true,
        roundedCornerRadius: 3,
        gradient: {
          colors: ['#d9ff65', '#40d04f']
        },
        scale: {
          start: 0,
          end: 1,
          stop: .8
        }
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-border-84-92',
        width: 84,
        height: 92,
        lineWidth: 5,
        roundedCorners: true,
        roundedCornerRadius: 3,
        lineColor: '#293249'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-68-74',
        width: 68,
        height: 74,
        roundedCorners: true,
        roundedCornerRadius: 3,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-50-56',
        width: 50,
        height: 56,
        roundedCorners: true,
        roundedCornerRadius: 2,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-progress-40-44',
        width: 40,
        height: 44,
        lineWidth: 3,
        roundedCorners: true,
        roundedCornerRadius: 1,
        gradient: {
          colors: ['#d9ff65', '#40d04f']
        },
        scale: {
          start: 0,
          end: 1,
          stop: .8
        }
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-border-40-44',
        width: 40,
        height: 44,
        lineWidth: 3,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#293249'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-30-32',
        width: 30,
        height: 32,
        roundedCorners: true,
        roundedCornerRadius: 1,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-40-44',
        width: 40,
        height: 44,
        roundedCorners: true,
        roundedCornerRadius: 1,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-dark-32-34',
        width: 32,
        height: 34,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#7750f8',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-32-36',
        width: 32,
        height: 36,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#1d2333',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-dark-26-28',
        width: 26,
        height: 28,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#7750f8',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-28-32',
        width: 28,
        height: 32,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#1d2333',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-dark-22-24',
        width: 22,
        height: 24,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#7750f8',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-22-24',
        width: 22,
        height: 24,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#1d2333',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-dark-16-18',
        width: 16,
        height: 18,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: '#7750f8',
        fill: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-120-130',
        width: 120,
        height: 130,
        roundedCorners: true,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-100-108',
        width: 100,
        height: 108,
        roundedCorners: true,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-124-136',
        width: 124,
        height: 136,
        roundedCorners: true,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-84-92',
        width: 84,
        height: 92,
        roundedCorners: true,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-34-36',
        width: 34,
        height: 36,
        roundedCorners: true,
        roundedCornerRadius: 1,
        fill: true,
        lineColor: '#1d2333'
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-40-44',
        width: 40,
        height: 44,
        roundedCorners: true,
        roundedCornerRadius: 1,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-24-26',
        width: 24,
        height: 26,
        roundedCorners: true,
        roundedCornerRadius: 1,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-image-18-20',
        width: 18,
        height: 20,
        roundedCorners: true,
        roundedCornerRadius: 1,
        clip: true
      });

      appHelper.plugins.createHexagon({
        container: '.hexagon-overlay-30-32',
        width: 30,
        height: 32,
        roundedCorners: true,
        roundedCornerRadius: 1,
        lineColor: 'rgba(97, 93, 250, .9)',
        fill: true
      });
    });
  }, []);

}

export default useSidebar