import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AuthSlice from "../../../../shared/reducers/auth.reducer";

const Dropdown = () => {
  const dispatch = useDispatch()
  const {signOut} = AuthSlice.actions
  const {userAccount} = useSelector(state => state.Auth)
  const logOut = () => {
    dispatch(signOut())
  }

  return (
    <div className="dropdown-navigation header-settings-dropdown">
      <div className="dropdown-navigation-header">
        <div className="user-status">
          <Link className="user-status-avatar"
                to={`${userAccount?.account?.role === 'channel' ? `/channel/${userAccount?.profile?.username}` : '/hub/profile/info'}`}>
            <div className="user-avatar small no-outline">
              <div className="user-avatar-content">
                <div
                  className="hexagon-image-30-32"
                  data-src={userAccount?.profile?.photoURL}
                />
              </div>
              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"/>
              </div>
              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"/>
              </div>
              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"/>
                </div>
                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"/>
                </div>
                <p className="user-avatar-badge-text">1</p>
              </div>
            </div>
          </Link>
          <p className="user-status-title">
            <span className="bold">Hi {userAccount?.profile?.name}!</span>
          </p>
          {userAccount?.profile?.username ? (
            <p className="user-status-text small">
              <Link to={`/channel/${userAccount?.profile?.username}`}>@{userAccount?.profile?.username}</Link>
            </p>
          ) : ''}
        </div>
      </div>
      <p className="dropdown-navigation-category">My Profile</p>
      <Link
        className="dropdown-navigation-link"
        to="/hub/profile-info"
      >
        Profile Info
      </Link>
      <Link
        className="dropdown-navigation-link"
        to="/hub/profile/social"
      >
        Social Media
      </Link>

      {/*<a
        className="dropdown-navigation-link"
        href="hub-profile-messages.html"
      >
        Messages
      </a>*/}
      {/*<a
        className="dropdown-navigation-link"
        href="hub-profile-requests.html"
      >
        Friend Requests
      </a>*/}
      <p className="dropdown-navigation-category">Account</p>
      {/*<Link
        className="dropdown-navigation-link"
        to="/hub/account/info"
      >
        Account Info
      </Link>*/}
      <Link
        className="dropdown-navigation-link"
        to="/hub/account/channel"
      >
        Channel
      </Link>
      <Link
        className="dropdown-navigation-link"
        to="/hub/account/notifications"
      >
        Notifications
      </Link>
      {/*<a
        className="dropdown-navigation-link"
        href="hub-account-settings.html"
      >
        General Settings
      </a>*/}
      {/* <p className="dropdown-navigation-category">Groups</p>
      <a
        className="dropdown-navigation-link"
        href="hub-group-management.html"
      >
        Manage Groups
      </a>
      <a
        className="dropdown-navigation-link"
        href="hub-group-invitations.html"
      >
        Invitations
      </a>*/}
      {/*<p className="dropdown-navigation-category">My Store</p>
      <a
        className="dropdown-navigation-link"
        href="hub-store-account.html"
      >
        My Account <span className="highlighted">$250,32</span>
      </a>
      <a
        className="dropdown-navigation-link"
        href="hub-store-statement.html"
      >
        Sales Statement
      </a>
      <a className="dropdown-navigation-link" href="hub-store-items.html">
        Manage Items
      </a>
      <a
        className="dropdown-navigation-link"
        href="hub-store-downloads.html"
      >
        Downloads
      </a>*/}
      <p className="dropdown-navigation-button button small secondary" onClick={logOut}>
        Logout
      </p>
    </div>
  )
}

export default Dropdown