import React from "react";
import Welcome from "./welcome";
import StatSlider from "./stat-slider";
import UserProgress from "./user-progress";
import StreamVideo from "../stream-video";
import Comments from "./comments";

const Predictions = () => {
  return (
    <div className="grid mobile-prefer-content">
      <div className="grid-column">
        <div className="grid grid-9-3">
          <div className="grid-column">
            <StreamVideo/>
          </div>
          <div className="grid-column">
            {/*<StatSlider/>*/}
            <Comments/>
            {/*<UserProgress />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Predictions;
