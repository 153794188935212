const SIDEBAR_NAVIGATION = [
  {
    id: 'newsfeed',
    show: false,
    label: 'Newsfeed',
    svgClass: 'icon-newsfeed',
    svgIcon: '#svg-newsfeed',
    link: '/soon/newsfeed'
  },
  {
    id: 'overview',
    show: false,
    label: 'Overview',
    svgClass: 'icon-overview',
    svgIcon: '#svg-overview',
    link: '/soon/overview'
  },
  {
    id: 'streams',
    show: true,
    label: 'Streams',
    svgClass: 'icon-streams',
    svgIcon: '#svg-streams',
    link: '/streams'
  },
  {
    id: 'guilds',
    show: true,
    label: 'Guilds',
    svgClass: 'icon-group',
    svgIcon: '#svg-group',
    link: '/soon/guilds'
  },
  {
    id: 'members',
    show: false,
    label: 'Members',
    svgClass: 'icon-members',
    svgIcon: '#svg-members',
    link: '/soon/members'
  },
  {
    id: 'badges',
    show: false,
    label: 'Badges',
    svgClass: 'icon-badges',
    svgIcon: '#svg-badges',
    link: '/soon/badges'
  },
  {
    id: 'quests',
    show: true,
    label: 'Quests',
    svgClass: 'icon-quests',
    svgIcon: '#svg-quests',
    link: '/soon/quests'
  },
  {
    id: 'events',
    show: true,
    label: 'Events',
    svgClass: 'icon-events',
    svgIcon: '#svg-events',
    link: '/soon/events'
  },
  {
    id: 'forums',
    show: true,
    label: 'Forums',
    svgClass: 'icon-forums',
    svgIcon: '#svg-forums',
    link: '/soon/forums'
  },
  {
    id: 'marketplace',
    show: true,
    label: 'Marketplace',
    svgClass: 'icon-marketplace',
    svgIcon: '#svg-marketplace',
    link: '/soon/marketplace'
  },

]
export default SIDEBAR_NAVIGATION
