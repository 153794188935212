import './assets/sass/main.scss'
import './assets/css/style.css'
import React, {Suspense} from "react"
import ReactDOM from 'react-dom/client'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from "react-redux"
import * as Sentry from "@sentry/react"
import {BrowserTracing} from "@sentry/tracing"
import configureAppStore from "./shared/configs/store.config"
import {AppRoutes} from "./routing/app-routes"
import PageLoader from "./components/page-loader/page-loader"

const store = configureAppStore()
const persistor = persistStore(store)

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(
  <Provider store={store}>
    <PersistGate loading={<PageLoader/>} persistor={persistor}>
      <AppRoutes/>
    </PersistGate>
  </Provider>
);
