const Progress = () => {
  return (
    <div className="progress-stat">
      <div className="bar-progress-wrap">
        <p className="bar-progress-info">
          Next: <span className="bar-progress-text" />
        </p>
      </div>
      <div id="logged-user-level" className="progress-stat-bar" />
    </div>
  );
};

export default Progress;
