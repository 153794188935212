import {useEffect} from "react";
import appHelper from "../helpers/app.helper";

const useHexagon = ()=>{
  useEffect(() => {
    appHelper.plugins.createHexagon({
      container: '.hexagon-148-164',
      width: 148,
      height: 164,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-progress-124-136',
      width: 124,
      height: 136,
      lineWidth: 8,
      roundedCorners: true,
      gradient: {
        colors: ['#d9ff65', '#40d04f']
      },
      scale: {
        start: 0,
        end: 1,
        stop: .0
      }
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-border-124-136',
      width: 124,
      height: 136,
      lineWidth: 8,
      roundedCorners: true,
      lineColor: '#293249'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-100-110',
      width: 100,
      height: 110,
      roundedCorners: true,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-120-132',
      width: 120,
      height: 132,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-progress-100-110',
      width: 100,
      height: 110,
      lineWidth: 6,
      roundedCorners: true,
      gradient: {
        colors: ['#d9ff65', '#40d04f']
      },
      scale: {
        start: 0,
        end: 1,
        stop: .0
      }
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-border-100-110',
      width: 100,
      height: 110,
      lineWidth: 6,
      roundedCorners: true,
      lineColor: '#293249'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-82-90',
      width: 82,
      height: 90,
      roundedCorners: true,
      roundedCornerRadius: 3,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-100-110',
      width: 100,
      height: 110,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-progress-84-92',
      width: 84,
      height: 92,
      lineWidth: 5,
      roundedCorners: true,
      roundedCornerRadius: 3,
      gradient: {
        colors: ['#d9ff65', '#40d04f']
      },
      scale: {
        start: 0,
        end: 1,
        stop: .8
      }
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-border-84-92',
      width: 84,
      height: 92,
      lineWidth: 5,
      roundedCorners: true,
      roundedCornerRadius: 3,
      lineColor: '#293249'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-68-74',
      width: 68,
      height: 74,
      roundedCorners: true,
      roundedCornerRadius: 3,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-50-56',
      width: 50,
      height: 56,
      roundedCorners: true,
      roundedCornerRadius: 2,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-progress-40-44',
      width: 40,
      height: 44,
      lineWidth: 3,
      roundedCorners: true,
      roundedCornerRadius: 1,
      gradient: {
        colors: ['#d9ff65', '#40d04f']
      },
      scale: {
        start: 0,
        end: 1,
        stop: .8
      }
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-border-40-44',
      width: 40,
      height: 44,
      lineWidth: 3,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#293249'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-30-32',
      width: 30,
      height: 32,
      roundedCorners: true,
      roundedCornerRadius: 1,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-40-44',
      width: 40,
      height: 44,
      roundedCorners: true,
      roundedCornerRadius: 1,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-dark-32-34',
      width: 32,
      height: 34,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#7750f8',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-32-36',
      width: 32,
      height: 36,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#1d2333',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-dark-26-28',
      width: 26,
      height: 28,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#7750f8',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-28-32',
      width: 28,
      height: 32,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#1d2333',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-dark-22-24',
      width: 22,
      height: 24,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#7750f8',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-22-24',
      width: 22,
      height: 24,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#1d2333',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-dark-16-18',
      width: 16,
      height: 18,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#7750f8',
      fill: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-120-130',
      width: 120,
      height: 130,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-100-108',
      width: 100,
      height: 108,
      roundedCorners: true,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-124-136',
      width: 124,
      height: 136,
      roundedCorners: true,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-84-92',
      width: 84,
      height: 92,
      roundedCorners: true,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-34-36',
      width: 34,
      height: 36,
      roundedCorners: true,
      roundedCornerRadius: 1,
      fill: true,
      lineColor: '#1d2333'
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-40-44',
      width: 40,
      height: 44,
      roundedCorners: true,
      roundedCornerRadius: 1,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-24-26',
      width: 24,
      height: 26,
      roundedCorners: true,
      roundedCornerRadius: 1,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-image-18-20',
      width: 18,
      height: 20,
      roundedCorners: true,
      roundedCornerRadius: 1,
      clip: true
    });

    appHelper.plugins.createHexagon({
      container: '.hexagon-overlay-30-32',
      width: 30,
      height: 32,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: 'rgba(97, 93, 250, .9)',
      fill: true
    });
  }, []);
  
}

export default useHexagon